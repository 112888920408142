import { DataService } from "../../utils/dataService/dataService";

const getLessonService = async () => {
  const response = await DataService.get(`/lesson`);
  return response;
};
const getSingleLesson = async (id) => {
  const response = await DataService.get(`/lesson/package/${id}`);
  return response;
};
const markAsComplete = async ({ body }) => {
  const response = await DataService.put(`/lesson/mark/complete/`, body);
  return response;
};

export { getLessonService, getSingleLesson, markAsComplete };
