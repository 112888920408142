import React from "react";
import "./error.css";

const NoData = () => {
  return (
    <div>
      <section class="error-page section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 offset-lg-3 col-12">
              <div class="error-inner">
                <h1>
                  404
                  <span>Oop's sorry we can't find that page!</span>
                </h1>
                <p>
                  The page you are looking for might have been removed had its
                  name changed or is temporarily unavailable.
                </p>
                <div class="button">
                  <a href="/" class="btn">
                    Back to homepage
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NoData;
