import timg1 from "../images/team/gagandeep.jpg";
import timg2 from "../images/team/2.jpg";
import timg3 from "../images/team/3.jpg";
import timg4 from "../images/team/4.jpg";

const Teams = [
  {
    Id: "1",
    tImg: timg1,
    slug: "Gagandeep Khattra",
    name: "Gagandeep Khattra",
    title: "RCIC- Member",
  },
  // {
  //     Id: '2',
  //     tImg:timg2,
  //     slug:'Dianne-Russell',
  //     name:'Dianne Russell',
  //     title:'UX Designer',
  // },
  // {
  //     Id: '3',
  //     tImg:timg3,
  //     slug:'Courtney-Henry',
  //     name:'Courtney Henry',
  //     title:'Senior Marketer',
  // },
  // {
  //     Id: '4',
  //     tImg:timg4,
  //     slug:'Annette-Black',
  //     name:'Annette Black',
  //     title:'Web Developer',
  // }
];

export default Teams;
