import React from "react";
import Layout from "./Layout";
import dayjs from "dayjs";
import NoData from "../../components/Nodata";
const OrderHistory = ({ data }) => {
  return (
    <Layout title={"Order History"}>
      <div class="table-responsive ">
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th>Order ID</th>
              <th>Course Name</th>
              <th>Date</th>
              <th>Price</th>
            </tr>
          </thead>
          {data?.data?.map((i, index) => {
            return (
              <tbody>
                <tr>
                  <td>
                    <div>{i?.orderId}</div>
                  </td>
                  <td>
                    <div
                      className="py-2 h6"
                      style={{
                        width: 120,
                      }}
                    >
                      {i?.package?.name}
                    </div>
                  </td>
                  <td>
                    <div
                      className="py-2 h6 sm-h5"
                      style={{
                        width: 100,
                      }}
                    >
                      {dayjs(i?.createdAt)?.format("DD MMM YY")}
                      <div>{dayjs(i?.createdAt)?.format("hh:mm A")}</div>
                    </div>
                  </td>
                  <td>
                    <div className="fw-bold">${i?.price}</div>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
        {!data?.data?.length && (
          <div className="mt-5">
            <NoData
              title="No order history"
              description="You have not ordered any course yet"
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default OrderHistory;
