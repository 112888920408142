import React from "react";
import Layout from "../Layout";
import DisplayEnrolledCourse from "./DisplayEnrolledCourse";
import LessonData from "./LessonData";
import Features from "./Features";
import NoData from "../../../components/Nodata";
const EnrolledCourses = ({
  data,
  setSelectedCourseTab,
  selectedCourseTab,
  enrolledCourses,
  lessonData,
  features,
}) => {
  const renderData = {
    1: enrolledCourses?.data?.length ? (
      <DisplayEnrolledCourse lesson={false} enrolledCourses={enrolledCourses} />
    ) : (
      <NoData
        title="There are no  courses available"
        description="Please enroll for a course to get started"
      />
    ),
    2: lessonData?.data?.length ? (
      <LessonData lesson lessonData={lessonData} />
    ) : (
      <NoData
        title="There are no lessons available"
        description="Please enroll for a course to get started"
      />
    ),
    3: features?.data?.length ? (
      <Features features={features} />
    ) : (
      <NoData
        title="There are no features available"
        description="Please enroll for a course to get started"
      />
    ),
  };
  return (
    <Layout title={"Enrolled Courses"}>
      <div className="d-flex flex-wrap  align-items-center gap-4   ">
        {data?.map((i) => {
          return (
            <div
              onClick={() => setSelectedCourseTab(i.id)}
              key={i.id}
              className=""
              style={{
                cursor: "pointer",
                color: selectedCourseTab === i.id ? "#2f57ef" : "#000",
                borderBottom:
                  selectedCourseTab === i.id ? "2px solid #2f57ef" : "",
                padding: 5,
                fontWeight: "bold",
              }}
            >
              <div>{i?.name}</div>
            </div>
          );
        })}
      </div>
      <div className="mt-4">{renderData[selectedCourseTab]}</div>
    </Layout>
  );
};

export default EnrolledCourses;
