import React from "react";
import { currentUser } from "../../jotaiStore/user";
import { useAtom } from "jotai";

const Render = ({ truncateText, course, navigate }) => {
  const [user] = useAtom(currentUser);

  const attempts = user?.packageBought === "rcic-basic" ? 3 : 2;

  return (
    <div className="wpo-popular-single">
      <div className="wpo-popular-item ">
        <div className="py-3 rounded-lg">
          <img
            src={course?.imageUrl}
            alt=""
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        </div>
        <div className="px-3" style={{ height: "100px" }}>
          <div className="fw-bold fs-4">{course?.name}</div>
          <div className="pt-1" style={{ fontSize: "15px" }}>
            {truncateText(course?.testDescription, 80)}
          </div>
          <div className="" style={{ fontSize: "18px" }}>
            <span className="text-primary fw-bold">
              {attempts - course?.attemptFinished}
            </span>{" "}
            {`Attempt${attempts - course?.attemptFinished < 2 ? "" : "s"}`} left
          </div>
        </div>
        <div
          className="d-flex justify-content-end"
          onClick={() => {
            navigate(`/rules/${course?._id}`);
          }}
        >
          <button
            style={{
              backgroundColor: "#F2BC00",
              borderRadius: "25px",
              padding: "5px 18px",
              border: "none",
              fontSize: "17px",
              fontWeight: "500",
              margin: "10px",
            }}
          >
            Start
          </button>
        </div>
      </div>
    </div>
  );
};

export default Render;
