/* eslint-disable react/no-unescaped-entities */
"use client";

import React, { useState } from "react";
import { BsRecord, BsRecordFill } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
const Result = ({ isFinished }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  return (
    <div>
      <div className="flex  mt-3">
        <div>
          <div className="my-2 h4 " style={{}}>
            Results
          </div>
          <div
            style={{
              marginBottom: 20,
            }}
          >
            <span
              className=" h5"
              style={{
                color: "#0b84e3",
              }}
            >
              {isFinished?.resultArray?.filter((i) => i?.isCorrect)?.length} of{" "}
            </span>
            <span
              style={{
                color: "#0b84e3",
              }}
              className=" h5"
            >
              {isFinished?.resultArray?.length}
            </span>
            <span className="mx-1">Questions answered correctly</span>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f1f3f6",
            padding: 10,
          }}
          className="d-flex flex-wrap  rounded-xl  w-100  my-3    text-black"
        >
          {isFinished?.resultArray?.map((i, index) => {
            return (
              <div
                role="button"
                onClick={() => {
                  setCurrentQuestion(index);
                }}
                key={i}
                className={`px-3 py-2   m-1 `}
                style={{
                  position: "relative",
                  borderRadius: 7,
                  borderColor: "#ccc",
                  borderWidth: 1,
                  borderStyle: "solid",
                  cursor: "pointer",
                  backgroundColor:
                    i?.isCorrect === true
                      ? "green"
                      : i?.isCorrect === false
                      ? "red"
                      : "#ccc",
                  color:
                    i?.isCorrect === true
                      ? "#fff"
                      : i?.isCorrect === false
                      ? "#fff"
                      : "#000",
                }}
              >
                {index + 1}
              </div>
            );
          })}
        </div>
        <div className="d-flex align-items-center ">
          <div className="d-flex justify-content-center align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="green"
              class="bi bi-square-fill"
              viewBox="0 0 16 16"
            >
              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z" />
            </svg>
            <div className="mx-1 h6 mt-2">Correct</div>
          </div>
          <div className="d-flex align-items-center mx-2">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="red"
                class="bi bi-square-fill"
                viewBox="0 0 16 16"
              >
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z" />
              </svg>
            </div>
            <div className="mx-1 h6 mt-2">InCorrect</div>
          </div>
          <div className="d-flex align-items-center ">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#ccc"
                class="bi bi-square-fill"
                viewBox="0 0 16 16"
              >
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z" />
              </svg>
            </div>
            <div className="mx-1 h6 mt-2">Not Attempted</div>
          </div>
        </div>

        <div className="   text-black p-2 w-100 mt-4">
          <div
            style={{
              fontFamily: "Mulish sans-serif ",
              userSelect: "none",
              fontSize: 5,
            }}
            className="htmlContent"
            dangerouslySetInnerHTML={{
              __html: isFinished?.resultArray?.[currentQuestion]?.name,
            }}
          />
        </div>
        <div className="d-flex border-top p-2">
          <div className="w-100">
            {isFinished?.resultArray?.[currentQuestion]?.options?.map(
              (answer, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      userSelect: "none",
                      backgroundColor:
                        isFinished?.resultArray?.[currentQuestion]
                          ?.correctOption === answer?.option
                          ? "#c8e6c9"
                          : isFinished?.resultArray?.[currentQuestion]
                              ?.attemptedOption === answer?.option
                          ? "#ffcdd3"
                          : "#f1f3f6",
                      borderRadius: 10,
                      marginBlock: 7,
                    }}
                    className="d-flex align-items-center p-2"
                  >
                    <div>
                      <>
                        {isFinished?.resultArray?.[currentQuestion]
                          ?.isCorrect ? (
                          <>
                            {isFinished?.resultArray?.[currentQuestion]
                              ?.correctOption === answer?.option ? (
                              <BsRecordFill
                                style={{
                                  fontSize: 30,
                                  color: "green",
                                }}
                              />
                            ) : (
                              <>
                                <BsRecord
                                  style={{
                                    fontSize: 30,
                                    color: "black",
                                  }}
                                />
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {isFinished?.resultArray?.[currentQuestion]
                              ?.attemptedOption ? (
                              <>
                                {isFinished?.resultArray?.[currentQuestion]
                                  ?.attemptedOption === answer?.option ? (
                                  <BsRecordFill
                                    style={{
                                      fontSize: 30,
                                      color: "red",
                                    }}
                                  />
                                ) : (
                                  <>
                                    {isFinished?.resultArray?.[currentQuestion]
                                      ?.correctOption === answer?.option ? (
                                      <>
                                        <BsRecordFill
                                          style={{
                                            fontSize: 30,
                                            color: "green",
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <BsRecord
                                          style={{
                                            fontSize: 30,
                                            color: "black",
                                          }}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <BsRecord
                                  style={{
                                    fontSize: 16,
                                    color: "black",
                                  }}
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    </div>

                    <div className="   ">
                      <span className="answertext">{answer.text}</span>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
