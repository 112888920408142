/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "../style.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";

const LessonData = ({ lessonData }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container m-0 p-0">
        <div className="row g-4 ">
          {["rcic"]?.map((i) => {
            const totalLessons = lessonData?.data?.filter(
              (j) => j?.packageName === i
            )?.length;
            return (
              <div className=" layout col-lg-4 col-12 ">
                <div className="">
                  <img
                    src={
                      "https://thedriverservices.s3.amazonaws.com/testPaper/work-ba308dd5-5e3c-4cca-bb49-5332fa8ede4c.svg"
                    }
                    alt=""
                    className=" rounded-start rounded-end rounded-top"
                    style={{
                      height: 200,
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                  <div className="card-body">
                    <h5 className="card-title text-uppercase">{i} </h5>
                    <div className="card-text">
                      <>
                        <div className="d-flex  align-items-center mt-3">
                          <i
                            class="fa fa-book h6 text-secondary"
                            aria-hidden="true"
                          ></i>
                          <div className="mx-2  h6 text-secondary ">
                            {totalLessons} {""}
                            Lessons
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-between my-1">
                          <div
                            className=" text-uppercase"
                            style={{
                              fontSize: 13,
                              fontWeight: "bold",
                              color: "#8c919a",
                            }}
                          >
                            Complete
                          </div>
                          <div
                            className=""
                            style={{
                              fontSize: 13,
                              fontWeight: "bold",
                              color: "#b4b9c2",
                            }}
                          >
                            0%
                          </div>
                        </div> */}
                        {/* <div className="my-3">
                          <ProgressBar
                            now={0}
                            variant="success"
                            animated
                            visuallyHidden={false}
                            style={{
                              borderRadius: 5,
                              height: 8,
                            }}
                          />
                        </div> */}
                      </>
                      <div
                        onClick={() => {
                          navigate(`/lesson?type=${i}`);
                        }}
                        role="button"
                        className="text-primary text-center  py-2 my-2"
                        style={{
                          background: "#e4e9fe",
                          borderRadius: 5,
                          fontWeight: "600",
                          textTransform: "uppercase",
                          fontSize: 14,
                          letterSpacing: 1,
                        }}
                      >
                        View
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default LessonData;
