const getItem = (key) => {
    if (typeof window !== 'undefined') {
        return localStorage.getItem(key);
    }
};

const setItem = (key, value) => {
    return localStorage.setItem(key, value);
};

const removeItem = (key) => {
    localStorage.removeItem(key);
};

const clearAll = () => {
    localStorage.clear();
};

export { getItem, setItem, removeItem, clearAll };
