"use client";

import style from "./index.module.css";

export function Loader4() {
  return (
    <div className={style?.wrapper}>
      <div className={style?.circle}></div>
      <div className={style?.circle}></div>
      <div className={style?.circle}></div>
      <div className={style?.shadow}></div>
      <div className={style?.shadow}></div>
      <div className={style?.shadow}></div>
    </div>
  );
}
