import { DataService } from "../../utils/dataService/dataService";

const startExamPaper = async (id) => {
  try {
    const response = await DataService.post(`/examAttempts/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

const updateExamPaper = async ({ id, body }) => {
  try {
    const response = await DataService.put(`/examAttempts/paper/${id}`, body);
    return response;
  } catch (err) {
    throw err;
  }
};
const getAttemptedPaperCount = async (id) => {
  try {
    const response = await DataService.get(
      `/examAttempts/attempted/count/${id}`
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export { startExamPaper, updateExamPaper, getAttemptedPaperCount };
