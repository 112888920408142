import React from "react";
import "./style.css";
const Layout = ({ title, children }) => {
  return (
    <div className=" layout bg-white  h-100">
      <div
        className="fw-bold h4 p-4"
        style={{
          borderBottom: "0.5px solid #ccc",
          paddingBottom: 20,
        }}
      >
        {title}
      </div>
      <div
        className="m-4"
        style={{
          height: "80%",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Layout;
