import { DataService } from "../../utils/dataService/dataService";

const getPaperService = async (endpoint, type) => {
  try {
    const response = await DataService.get(`${endpoint}?type=${type}`);
    return response;
  } catch (err) {
    throw err;
  }
};
const getEnrolledCourses = async (query) => {
  try {
    const response = await DataService.get(`/testPaper/enrolled?${query}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export { getPaperService, getEnrolledCourses };
