import React, { useEffect, useState } from "react";
import SidebarMenu from "./SidebarMenu";
import Header from "./Header";
import Main from "./Main";
import NoData from "./NoData";

import { Loader2 } from "../../components/loader";
import { getSingleLesson } from "../../services/lesson/service";
import { useLocation } from "react-router-dom";
const _ = require("lodash");
const LessonPage = () => {
  const [selectedTopic, setSelectedTopic] = useState({});
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectPracticePaper, setSelectPracticePaper] = useState([]);
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery().get("type");

  const [getLessonsList, setGetLessonsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState({
    open: true,
    collapseWidth: 0,
  });
  const getLessons = () => {
    getSingleLesson(query)
      .then((res) => {
        setGetLessonsList(res?.data?.data);
        const firstLesson = res?.data?.data?.lessons?.[0]?._id;
        setSelectedIndex(firstLesson);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (query) getLessons();
  }, [query]);
  const isMobile = window.innerWidth <= 768;
  return (
    <div
      className=""
      style={{
        display: "block",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {query ? (
        <>
          {loading ? (
            <div
              className=""
              style={{
                backgroundColor: "#f7f7f7",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader2 />
            </div>
          ) : (
            <>
              {getLessonsList?.lessons?.length ? (
                <>
                  <div
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "white",
                      zIndex: 1000,
                    }}
                  >
                    <Header
                      setMenu={setMenu}
                      menu={menu}
                      getLessonsList={getLessonsList}
                      setSelectedIndex={setSelectedIndex}
                      selectedIndex={selectedIndex}
                      setSelectedTopic={setSelectedTopic}
                    />
                  </div>
                  <div className="d-flex items-content-center">
                    <div
                    // style={{
                    //   position: "sticky",
                    //   bottom: 0,
                    //   top: 120,
                    //   backgroundColor: "white",
                    //   zIndex: 900,
                    // }}
                    >
                      <SidebarMenu
                        isMobile={isMobile}
                        setMenu={setMenu}
                        menu={menu}
                        result={getLessonsList}
                        setSelectedTopic={setSelectedTopic}
                        selectedTopic={selectedTopic}
                        setSelectedIndex={setSelectedIndex}
                        selectedIndex={selectedIndex}
                        setSelectPracticePaper={setSelectPracticePaper}
                        selectPracticePaper={selectPracticePaper}
                      />
                    </div>
                    <div className="w-100">
                      <Main
                        setSelectedTopic={setSelectedTopic}
                        selectedTopic={selectedTopic}
                        getLessonsList={getLessonsList}
                        setGetLessonsList={setGetLessonsList}
                        setSelectedIndex={setSelectedIndex}
                        selectedIndex={selectedIndex}
                        lessonName={getLessonsList?.lessons?.find(
                          (i) => i._id === selectedIndex
                        )}
                        setSelectPracticePaper={setSelectPracticePaper}
                        selectPracticePaper={selectPracticePaper}
                        query={query}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <NoData />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default LessonPage;
