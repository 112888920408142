import React, { useCallback, useEffect } from "react";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAtom } from "jotai";
import { currentUser } from "../../jotaiStore/user";
import { currentUserService } from "../../services/user/service";

const App = () => {
  const [user, setUser] = useAtom(currentUser);

  const currentUserFun = useCallback(() => {
    currentUserService()
      .then((res) => {
        setUser(res?.data?.data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    currentUserFun();
  }, [currentUserFun]);

  return (
    <div className="App" id="scrool">
      <AllRoute />
      <ToastContainer />
    </div>
  );
};

export default App;
