/* eslint-disable react/no-unescaped-entities */
"use client";

import React, { useEffect, useState } from "react";
import style from "./index.module.css";
import { BsFillBookmarkFill, BsRecord, BsRecordFill } from "react-icons/bs";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.module.css";
export const QuestionsModule = ({
  getTestPaperData,
  setLoading,
  updateExamPaperFun,
  setSelectedOptions,
  selectedOptions,
  isExamFinish,
}) => {
  //to get current Question index
  const [currentQuestion, setCurrentQuestion] = useState(0);

  //to filter right Container in UI
  const [attemptedQuestions, setAttemptedQuestions] = useState([]);
  const handlePrevious = () => {
    const prevQues = currentQuestion - 1;
    prevQues >= 0 && setCurrentQuestion(prevQues);
  };
  const [open, setOpen] = useState(false);
  const handleNext = async ({ id }) => {
    if (!selectedOptions?.[currentQuestion]?.attemptedOption) {
      const updatedQuestions = attemptedQuestions?.filter(
        (i) => i !== currentQuestion
      );
      setAttemptedQuestions(updatedQuestions);
      setSelectedOptions([
        (selectedOptions[currentQuestion] = {
          ...selectedOptions[currentQuestion],
          questionId: id,
        }),
      ]);
      setSelectedOptions([...selectedOptions]);
    }
    if (currentQuestion + 1 === getTestPaperData?.data?.length) {
      setLoading(true);
      updateExamPaperFun();
    } else {
      const nextQues = currentQuestion + 1;
      nextQues < getTestPaperData?.data?.length && setCurrentQuestion(nextQues);
    }
  };
  const handleAnswerOption = ({ id, attemptedOption, isCorrect }) => {
    if (!attemptedQuestions?.includes(currentQuestion)) {
      setAttemptedQuestions([...attemptedQuestions, currentQuestion]);
    }
    setSelectedOptions([
      (selectedOptions[currentQuestion] = {
        ...selectedOptions[currentQuestion],
        attemptedOption: attemptedOption,
        questionId: id,
        isCorrect,
      }),
    ]);
    setSelectedOptions([...selectedOptions]);
  };
  const totalTime = +getTestPaperData?.testPaperDetails?.totalTime;
  const initialSeconds = totalTime ? +totalTime * 60 : 0;
  const [remainingTime, setRemainingTime] = useState(initialSeconds);

  useEffect(() => {
    let intervalId;
    if (getTestPaperData?._id && !isExamFinish) {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
      if (remainingTime === 0) {
        setLoading(true);
        updateExamPaperFun();
      }
    }
    return () => clearInterval(intervalId);
  }, [remainingTime, getTestPaperData?._id, isExamFinish]);

  const hours = Math.floor(remainingTime / 3600);
  const minutes = Math.floor((remainingTime % 3600) / 60);
  const seconds = remainingTime % 60;

  return (
    <div>
      <Modal
        open={open}
        animationDuration={500}
        onClose={() => {
          setOpen(false);
        }}
        center
      >
        <div className="rounded-md bg-white p-6">
          <div>
            <div>
              <div className="font-mulish text-lg text-black">
                Are you sure?
              </div>
              <div className="font-mulish text-lg text-black">
                You still wish to close the test despite the fact that there are
                just a few{" "}
                <span className="font-bold text-red-500">bookmarks</span>{" "}
                remaining..
              </div>
            </div>
          </div>
          <div className="mt-3 flex w-full justify-end">
            <button
              className="flex justify-end rounded-md bg-black px-3 py-1 font-mulish text-white"
              onClick={() => {
                setLoading(true);
                updateExamPaperFun();
              }}
            >
              Submit test
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex  ">
        <div className="">
          <div
            class="shadow  mb-5 bg-white rounded"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <img
              src="https://assets.onecompiler.app/42zmnshnj/4358f35k9/download.png"
              alt="2BRCIC"
              width="200"
              height="80"
            />
          </div>
        </div>
        <div className="row ">
          <div className={`col-7 p-0 `}>
            <div className={`${style.card} pb-5`}>
              <div className="d-flex flex-column   justify-content-center align-items-center">
                <div
                  className="px-5 rounded-xl mt-2"
                  style={{
                    background:
                      remainingTime < 600
                        ? "red"
                        : remainingTime < 1200
                        ? "yellow "
                        : remainingTime < 1800
                        ? "orange"
                        : "#ccc",
                    borderRadius: 10,
                  }}
                >
                  <div
                    className=" pt-0 text-center   font-mulish text-xl  "
                    style={{
                      color:
                        remainingTime < 600
                          ? "#fff"
                          : remainingTime < 1200
                          ? "#000"
                          : remainingTime < 1800
                          ? "#000"
                          : "#000",
                    }}
                  >
                    Time remaining
                  </div>
                  <div
                    style={{
                      color:
                        remainingTime < 600
                          ? "#fff"
                          : remainingTime < 1200
                          ? "#000"
                          : remainingTime < 1800
                          ? "#000"
                          : "#000",
                    }}
                  >
                    <div className="ml-2 text-xl text-center">
                      {hours.toString().padStart(2, "0")}:
                      {minutes.toString().padStart(2, "0")}:
                      {seconds.toString().padStart(2, "0")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end  ">
                <h4 className="black px-3 font-mulish fs-6">
                  Page {currentQuestion + 1} of {getTestPaperData?.data?.length}
                </h4>
              </div>
              <div className="   text-black p-2">
                <div className="mr-7 font-mulish">Q.</div>

                <div
                  style={{
                    fontFamily: "Mulish sans-serif ",
                    userSelect: "none",
                    fontSize: 3,
                  }}
                  className={style.htmlContent}
                  dangerouslySetInnerHTML={{
                    __html: getTestPaperData?.data?.[currentQuestion]?.name,
                  }}
                />
              </div>
              <div className="d-flex border-top p-2">
                <div className="mr-5 font-mulish ">A.</div>
                <div className="mx-3">
                  {getTestPaperData?.data?.[currentQuestion]?.options?.map(
                    (answer, index) => (
                      <div
                        key={index}
                        style={{
                          userSelect: "none",
                        }}
                        className="d-flex align-items-center  m-1"
                        onClick={(e) =>
                          handleAnswerOption({
                            id: getTestPaperData?.data?.[currentQuestion]?._id,
                            attemptedOption: answer?.option,
                            isCorrect:
                              getTestPaperData?.data?.[currentQuestion]?.answer
                                ?.option === answer?.option
                                ? true
                                : false,
                          })
                        }
                      >
                        <div className=" h6  ">
                          {answer.option ===
                          selectedOptions?.[currentQuestion]
                            ?.attemptedOption ? (
                            <BsRecordFill
                              style={{
                                fontSize: 16,
                                marginRight: 2,
                                color: "green",
                              }}
                            />
                          ) : (
                            <BsRecord
                              style={{
                                fontSize: 16,
                                marginRight: 2,
                                color: "black",
                              }}
                            />
                          )}
                          <span
                            style={{
                              fontSize: 13,
                            }}
                          >
                            {answer.text}
                          </span>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className="mt-4   px-3 d-flex justify-content-between">
                {open ? (
                  <></>
                ) : (
                  <div className="d-flex align-items-center w-100 ">
                    {currentQuestion + 1 !== getTestPaperData?.data?.length ? (
                      <>
                        {selectedOptions?.[currentQuestion]?.isBookmark ? (
                          <button
                            className=" h6 px-3 border-1 py-1"
                            onClick={() => {
                              setSelectedOptions([
                                (selectedOptions[currentQuestion] = {
                                  ...selectedOptions[currentQuestion],
                                  isBookmark: false,
                                }),
                              ]);
                              setSelectedOptions([...selectedOptions]);
                            }}
                          >
                            Bookmarked
                          </button>
                        ) : (
                          <button
                            className=" h6 px-3 border-1 py-1"
                            onClick={() => {
                              setSelectedOptions([
                                (selectedOptions[currentQuestion] = {
                                  ...selectedOptions[currentQuestion],
                                  isBookmark: true,
                                }),
                              ]);
                              setSelectedOptions([...selectedOptions]);
                            }}
                          >
                            Bookmark
                          </button>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    <button
                      onClick={() => {
                        setOpen(true);
                      }}
                      className="mx-2 h6 px-3 border-1 py-1"
                    >
                      Submit Test
                    </button>
                  </div>
                )}
                <div className="d-flex justify-content-end  w-100 ">
                  {currentQuestion === 0 ? null : (
                    <button
                      onClick={handlePrevious}
                      className=" h6 px-3 border-1 py-1"
                    >
                      {currentQuestion === 0 ? "" : "Previous"}
                    </button>
                  )}

                  {currentQuestion + 1 !== getTestPaperData?.data?.length ? (
                    <>
                      {selectedOptions?.[currentQuestion]?.questionId ? (
                        <button
                          className=" h6 px-3 border-1 py-1 mx-2"
                          onClick={() => {
                            handleNext({
                              id: getTestPaperData?.data?.[currentQuestion]
                                ?._id,
                            });
                          }}
                        >
                          Next
                        </button>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div className="col-2"></div>
          <div className="col-3  ">
            <div className=" h-full  w-3/4  rounded-md    bg-[#fff] ">
              <div className=" p-3 text-center  border">Questions overview</div>
              <div className="bg-success text-white p-3 text-center ">
                Completed
              </div>

              <div className="  bg-secondary text-white p-3 text-center ">
                Not Completed
              </div>
              <div className="row d-flex justify-content-center ">
                {getTestPaperData?.testPaperDetails?._id ? (
                  // getTestPaperData?.testPaperDetails?.questions?.map(
                  getTestPaperData?.data?.map((i, index) => {
                    return (
                      <div
                        onClick={() => {
                          setCurrentQuestion(index);
                        }}
                        key={i}
                        className={`  ${style.box}  ${
                          attemptedQuestions?.includes(index)
                            ? `bg-success text-white`
                            : `bg-white text-black`
                        }`}
                        style={{
                          width: 30,
                          padding: 5,
                          margin: 5,
                          textAlign: "center",
                          cursor: "pointer",
                          fontSize: 10,
                          position: "relative",
                        }}
                      >
                        {selectedOptions?.[index]?.isBookmark ? (
                          <div className="bg-success">
                            <BsFillBookmarkFill
                              style={{
                                fontSize: 13,
                                position: "absolute",
                                color: "red",
                                top: -5,
                                right: 0,
                                zIndex: 100,
                              }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {index + 1}
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
