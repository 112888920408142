import React, { useEffect, useState } from "react";
import { TestPaperModule } from "../../components/TestPaperModule";
import { useNavigate } from "react-router-dom";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { reloadModalAtom } from "../../jotaiStore/exam";
import { useAtom } from "jotai";

const App = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useAtom(reloadModalAtom);
  useEffect(() => {
    window.onload = function () {
      console.log("reloaded");
      setModal(true);

      // return "Take care now, bye-bye then.";
    };
  }, []);
  useEffect(() => {
    if (modal) {
      function AddOnload(myfunc) {
        if (window.addEventListener)
          window.addEventListener("load", myfunc, false);
        else if (window.attachEvent) window.attachEvent("onload", myfunc);
      }
      AddOnload();
    }
  }, [modal]);
  return (
    <div>
      <Modal
        open={modal}
        showCloseIcon={false}
        animationDuration={500}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={() => {
          setModal(false);
        }}
        center
        styles={{
          background: "white",
        }}
      >
        <div className="rounded-md bg-primary">
          <div class="bg-white">
            <div class="text-danger fw-bold px-3">WARNING!!</div>
            <div class="modal-body">
              <p>Are you sure you want to quit the test?</p>
              <div>Your data will be lost.</div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => {
                  setModal(false);
                }}
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  setModal(false);
                  navigate("/");
                }}
              >
                Leave
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <TestPaperModule />
    </div>
  );
};

export default App;
