/* eslint-disable react/no-unescaped-entities */
"use client";

import React, { useEffect, useState } from "react";
import { BsFillBookmarkFill, BsRecord, BsRecordFill } from "react-icons/bs";
import "react-responsive-modal/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ProgressBar, Spinner } from "react-bootstrap";
import { markAsComplete } from "../../../services/lesson/service";
import { toast } from "react-toastify";
import { currentUser } from "../../../jotaiStore/user";
import { useAtom } from "jotai";
const Quiz = ({
  selectPracticePaper,
  setIsFinished,
  getLessonsList,
  setGetLessonsList,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user] = useAtom(currentUser);

  //to get current Question index
  const [currentQuestion, setCurrentQuestion] = useState(0);

  //to filter right Container in UI
  const [attemptedQuestions, setAttemptedQuestions] = useState([]);
  const handlePrevious = () => {
    const prevQues = currentQuestion - 1;
    prevQues >= 0 && setCurrentQuestion(prevQues);
  };

  const handleNext = async ({ id }) => {
    if (!selectedOptions?.[currentQuestion]?.attemptedOption) {
      const updatedQuestions = attemptedQuestions?.filter(
        (i) => i !== currentQuestion
      );
      setAttemptedQuestions(updatedQuestions);
      setSelectedOptions([
        (selectedOptions[currentQuestion] = {
          ...selectedOptions[currentQuestion],
          questionId: id,
        }),
      ]);
      setSelectedOptions([...selectedOptions]);
    }
    if (currentQuestion + 1 === selectPracticePaper?.length) {
    } else {
      const nextQues = currentQuestion + 1;
      nextQues < selectPracticePaper?.length && setCurrentQuestion(nextQues);
    }
  };
  const handleAnswerOption = ({ id, attemptedOption, isCorrect }) => {
    if (!attemptedQuestions?.includes(currentQuestion)) {
      setAttemptedQuestions([...attemptedQuestions, currentQuestion]);
    }
    setSelectedOptions([
      (selectedOptions[currentQuestion] = {
        ...selectedOptions[currentQuestion],
        attemptedOption: attemptedOption,
        questionId: id,
        isCorrect,
      }),
    ]);
    setSelectedOptions([...selectedOptions]);
  };
  const totalTime = 30;
  const initialSeconds = totalTime ? +totalTime * 60 : 0;
  const [remainingTime, setRemainingTime] = useState(initialSeconds);

  useEffect(() => {
    let intervalId;
    intervalId = setInterval(() => {
      setRemainingTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    if (remainingTime === 0) {
      setLoading("border");

      markAsComplete({
        body: {
          lessonId: selectPracticePaper?.[0]?.lessonId,
        },
      })
        .then(() => {
          setLoading("");
          const resultArray = selectPracticePaper?.map((i, index) => {
            return {
              attemptedOption: selectedOptions?.[index]?.attemptedOption,
              isCorrect: selectedOptions?.[index]?.isCorrect,
              questions: i?.name,
              options: i?.options,
              correctOption: i?.answer?.option,
            };
          });

          const updatedData = getLessonsList?.lessons?.map((i) => {
            if (i._id === selectPracticePaper?.[0]?.lessonId) {
              return {
                ...i,
                testCompleted: [...(i?.testCompleted || []), user?._id],
              };
            } else {
              return i;
            }
          });
          setGetLessonsList({
            ...getLessonsList,
            lessons: updatedData,
          });

          setIsFinished({
            finished: true,
            resultArray,
          });
          window.scrollTo({ top: 0, behavior: "smooth" });

          toast.success("Successfully submitted the test! ", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch(() => {
          setLoading();
        });
    }
    return () => clearInterval(intervalId);
  }, [remainingTime]);

  const hours = Math.floor(remainingTime / 3600);
  const minutes = Math.floor((remainingTime % 3600) / 60);
  const seconds = remainingTime % 60;

  return (
    <div>
      <div className="flex  ">
        <div className="d-flex my-3">
          <div
            className="rounded-xl mt-2  w-100"
            style={{
              backgroundColor: "#f1f3f6",
              padding: 10,
            }}
          >
            <div className="d-flex align-items-center">
              <div className="">Time limit :</div>
              <div>
                <div className="text-black mx-2">
                  {hours.toString().padStart(2, "0")}:
                  {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </div>
              </div>
            </div>
            <ProgressBar
              now={100 - (remainingTime / initialSeconds) * 100}
              variant="success"
              animated
              visuallyHidden={false}
              style={{
                borderRadius: 5,
                height: 8,
                width: "100%",
                marginTop: 10,
                background: "#fff",
              }}
            />
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f1f3f6",
            padding: 10,
          }}
          className="d-flex flex-wrap  rounded-xl  w-100  my-3    text-black"
        >
          {selectPracticePaper?.map((i, index) => {
            return (
              <div
                role="button"
                onClick={() => {
                  setCurrentQuestion(index);
                }}
                key={i}
                className={`px-3 py-2   m-1 `}
                style={{
                  position: "relative",
                  borderRadius: 7,
                  borderColor: currentQuestion === index ? "#0b84e3" : "#ccc",
                  borderWidth: currentQuestion === index ? 2 : 1,
                  borderStyle: "solid",
                  cursor: "pointer",
                  backgroundColor: attemptedQuestions?.includes(index)
                    ? `#0b84e3`
                    : `#fff`,
                  color: attemptedQuestions?.includes(index) ? `#fff` : `#000`,
                }}
              >
                {selectedOptions?.[index]?.isBookmark ? (
                  <div style={{}}>
                    <BsFillBookmarkFill
                      style={{
                        fontSize: 13,
                        color: "red",
                        position: "absolute",
                        top: -5,
                        right: 0,
                        zIndex: 1000,
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {index + 1}
              </div>
            );
          })}
        </div>
        <div className="d-flex align-items-center ">
          <div className="d-flex justify-content-center align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#0b84e3"
              class="bi bi-square"
              viewBox="0 0 16 16"
            >
              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
            </svg>
            <div className="mx-1 h6 mt-2">Current</div>
          </div>
          <div className="d-flex align-items-center mx-2">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="red"
                class="bi bi-square-fill"
                viewBox="0 0 16 16"
              >
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z" />
              </svg>
            </div>
            <div className="mx-1 h6 mt-2">Bookmark</div>
          </div>
          <div className="d-flex align-items-center ">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#0b84e3"
                class="bi bi-square-fill"
                viewBox="0 0 16 16"
              >
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z" />
              </svg>
            </div>
            <div className="mx-1 h6 mt-2">Answered</div>
          </div>
        </div>

        <div className="d-flex mt-3 justify-content-between w-100">
          {/* {currentQuestion + 1 !== selectPracticePaper?.length ? ( */}
          <>
            {selectedOptions?.[currentQuestion]?.isBookmark ? (
              <div
                role="button"
                className="inprogressbtn h6 px-4"
                onClick={() => {
                  setSelectedOptions([
                    (selectedOptions[currentQuestion] = {
                      ...selectedOptions[currentQuestion],
                      isBookmark: false,
                    }),
                  ]);
                  setSelectedOptions([...selectedOptions]);
                }}
              >
                Bookmarked
              </div>
            ) : (
              <div
                role="button"
                className="inprogressbtn h6 px-4"
                onClick={() => {
                  setSelectedOptions([
                    (selectedOptions[currentQuestion] = {
                      ...selectedOptions[currentQuestion],
                      isBookmark: true,
                    }),
                  ]);
                  setSelectedOptions([...selectedOptions]);
                }}
              >
                Bookmark
              </div>
            )}
          </>
          {/* ) : (
              <></>
            )} */}
          <div
            onClick={() => {
              if (window.confirm("Are you sure you want to submit?") === true) {
                setLoading("border");

                markAsComplete({
                  body: {
                    lessonId: selectPracticePaper?.[0]?.lessonId,
                  },
                })
                  .then(() => {
                    setLoading("");

                    const resultArray = selectPracticePaper?.map((i, index) => {
                      return {
                        attemptedOption:
                          selectedOptions?.[index]?.attemptedOption,
                        isCorrect: selectedOptions?.[index]?.isCorrect,
                        questions: i?.name,
                        options: i?.options,
                        correctOption: i?.answer?.option,
                      };
                    });

                    const updatedData = getLessonsList?.lessons?.map((i) => {
                      if (i._id === selectPracticePaper?.[0]?.lessonId) {
                        return {
                          ...i,
                          testCompleted: [
                            ...(i?.testCompleted || []),
                            user?._id,
                          ],
                        };
                      } else {
                        return i;
                      }
                    });
                    setGetLessonsList({
                      ...getLessonsList,
                      lessons: updatedData,
                    });

                    setIsFinished({
                      finished: true,
                      resultArray,
                    });
                    window.scrollTo({ top: 0, behavior: "smooth" });

                    toast.success("Successfully submitted the test! ", {
                      position: "top-right",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  })
                  .catch(() => {
                    setLoading();
                  });
              }
            }}
            role="button"
            className=" inprogressbtn px-4 h6"
          >
            <div className="d-flex align-items-center">
              <Spinner
                as="span"
                animation={loading}
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <div className="mx-2">Mark as complete</div>
            </div>
          </div>
        </div>

        <div className="   text-black p-2 w-100 mt-4">
          <div
            style={{
              fontFamily: "Mulish sans-serif ",
              userSelect: "none",
              fontSize: 5,
            }}
            className="htmlContent"
            dangerouslySetInnerHTML={{
              __html: selectPracticePaper?.[currentQuestion]?.name,
            }}
          />
        </div>
        <div className="d-flex border-top p-2">
          {/* <div className="mr-5 font-mulish ">A.</div> */}
          <div className="w-100">
            {selectPracticePaper?.[currentQuestion]?.options?.map(
              (answer, index) => (
                <div
                  key={index}
                  style={{
                    userSelect: "none",
                    backgroundColor: "#f1f3f6",
                    borderRadius: 10,
                    marginBlock: 7,
                  }}
                  className="d-flex align-items-center   p-2"
                  onClick={(e) =>
                    handleAnswerOption({
                      id: selectPracticePaper?.[currentQuestion]?._id,
                      attemptedOption: answer?.option,
                      isCorrect:
                        selectPracticePaper?.[currentQuestion]?.answer
                          ?.option === answer?.option
                          ? true
                          : false,
                    })
                  }
                >
                  <div>
                    {answer.option ===
                    selectedOptions?.[currentQuestion]?.attemptedOption ? (
                      <BsRecordFill
                        style={{
                          fontSize: 20,
                          marginRight: 2,
                          color: "green",
                        }}
                      />
                    ) : (
                      <BsRecord
                        style={{
                          fontSize: 20,
                          marginRight: 2,
                          color: "black",
                        }}
                      />
                    )}
                  </div>
                  <div className="   ">
                    <span className="answertext">{answer.text}</span>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <div className="mt-4  d-flex  justify-content-between ">
          {currentQuestion === 0 ? null : (
            <div
              onClick={handlePrevious}
              role="button"
              className="inprogressbtn h6 px-4 "
            >
              {currentQuestion === 0 ? "" : "Previous"}
            </div>
          )}

          {currentQuestion + 1 !== selectPracticePaper?.length ? (
            <>
              {/* {selectedOptions?.[currentQuestion]?.questionId ? ( */}
              <div
                role="button"
                className="inprogressbtn h6 px-4 "
                onClick={() => {
                  handleNext({
                    id: selectPracticePaper?.[currentQuestion]?._id,
                  });
                }}
              >
                Next
              </div>
              {/* ) : (
                <></>
              )} */}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Quiz;
