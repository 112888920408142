import React from "react";
import Layout from "./Layout";
import dayjs from "dayjs";
import "./style.css";
import NoData from "../../components/Nodata";
const TestResults = ({ data }) => {
  return (
    <Layout title={"Test Result"}>
      <div>
        {data?.data?.length ? (
          data?.data?.map((i) => {
            return (
              <div
                key={i}
                style={{
                  borderBottomWidth: "1px",
                  borderBottomColor: "#000",
                  marginBottom: 30,
                }}
              >
                <h3>
                  {i?.name}{" "}
                  <span
                    className="text-uppercase fw-bold h5"
                    style={{ color: "#6c82ea" }}
                  >
                    ({i?.courseName})
                  </span>
                </h3>

                <div class="table-responsive ">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Marks</th>
                        <th>Questions</th>
                        <th>Percentage</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    {i?.examResult?.length ? (
                      i?.examResult?.map((i, index) => {
                        return (
                          <tbody key={i?._id}>
                            <tr>
                              <td>
                                <div
                                  className="py-2 h6 sm-h5"
                                  style={{
                                    width: 100,
                                  }}
                                >
                                  Attempt - {index + 1}
                                </div>
                              </td>
                              <td>
                                <div className="py-2">
                                  <div className="h6 sm-h5">
                                    {i?.totalScore}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="py-2">
                                  <div className="h6 sm-h5">{i?.questions}</div>
                                </div>
                              </td>
                              <td>
                                <div className="py-2">
                                  <div className="h6 sm-h5">
                                    {i?.percentage}%
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  className="py-2"
                                  style={{
                                    width: 100,
                                  }}
                                >
                                  <div className="h6 sm-h5">
                                    {dayjs(i?.createdAt)?.format("DD MMM YY")}
                                  </div>
                                  <div className="h6 sm-h5">
                                    {dayjs(i?.createdAt)?.format("hh:mm A")}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })
                    ) : (
                      <>
                        <td colSpan={5}>
                          <div className="py-4 text-center">
                            No Attempt has been made yet
                          </div>
                        </td>
                      </>
                    )}
                  </table>
                </div>
              </div>
            );
          })
        ) : (
          <div className="">
            <NoData
              title={"No Test Attempted Yet"}
              // description={"You have not attempted any test yet"}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default TestResults;
