import { DataService } from "../../utils/dataService/dataService";
import { setItem } from "../../utils/dataService/localStorageControl";

const login = async ({ body }) => {
  try {
    const response = await DataService.post("/auth/login", body);
    setItem("access_token", response.data.data.accessToken);
    setItem("refresh_token", response.data.data.refreshToken);
    return response;
  } catch (err) {
    throw err;
  }
};
const signUp = async ({ body }) => {
  try {
    const response = await DataService.post(`/auth/register`, body);
    return response;
  } catch (err) {
    throw err;
  }
};
const forgotPassword = async ({ body }) => {
  try {
    const response = await DataService.post(`/auth/forgotPassword`, body);
    return response;
  } catch (err) {
    throw err;
  }
};
const verifyOtp = async (token) => {
  try {
    const response = await DataService.post(`/auth/verifyOtp/${token}`);
    return response;
  } catch (err) {
    throw err;
  }
};
const resetPassword = async (token) => {
  try {
    const response = await DataService.post(`/auth/resetpassword/${token}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export { login, signUp, forgotPassword, verifyOtp, resetPassword };
