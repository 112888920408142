import React from "react";

const NoData = ({ title, description }) => {
  return (
    <div>
      <div className="   ">
        <div className="flex flex-col">
          <span className="text-center font-bold opacity-30"></span>

          <div className="flex flex-col items-center text-center">
            <h1 className="display-1">{title}.</h1>

            <div className="display-7 text-muted text-center">
              {description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoData;
