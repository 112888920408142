import { DataService } from "../../utils/dataService/dataService";

const currentUserService = async () => {
  try {
    const response = await DataService.get("/me");
    return response;
  } catch (err) {
    throw err;
  }
};
const getUserPackagesData = async () => {
  try {
    const response = await DataService.get(`/user/testPaper/`);
    return response;
  } catch (err) {
    throw err;
  }
};
const getUserTestPaper = async () => {
  try {
    const response = await DataService.get(`/user/test/paper/details`);
    return response;
  } catch (err) {
    throw err;
  }
};
const updateUser = async ({ body }) => {
  try {
    const response = await DataService.put("/user", body);
    return response;
  } catch (err) {
    throw err;
  }
};
const getOrderService = async () => {
  try {
    const response = await DataService.get("/user/order");
    return response;
  } catch (err) {
    throw err;
  }
};
const verifyUserEmailService = async ({ token }) => {
  try {
    const response = await DataService.put(`/user/verifyEmail/${token}`);
    return response;
  } catch (err) {
    throw err;
  }
};
const resendVerificationEmail = async () => {
  try {
    const response = await DataService.put(`/user/resend/verify/`);
    return response;
  } catch (err) {
    throw err;
  }
};

export {
  currentUserService,
  getUserTestPaper,
  updateUser,
  getUserPackagesData,
  getOrderService,
  verifyUserEmailService,
  resendVerificationEmail,
};
