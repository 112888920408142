import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { addContactUs } from "../../services/contactUs/service";
import { Zoom, toast } from "react-toastify";
import {Loader} from "../../components/loader";

const ContactForm = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    city: "",
    phone: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const addContactUsFun = () => {
    setLoading(true);
    addContactUs({
      body: {
        name: forms.name,
        email: forms.email,
        phone: forms.phone,
        message: forms.message,
        city: forms.city,
      },
    })
      .then(() => {
        toast.success("Enquiry has been sent successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Zoom,
          role: "alert",
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setForms({
        name: "",
        email: "",
        city: "",
        phone: "",
        message: "",
      });
      addContactUsFun();
    } else {
      validator.showMessages();
    }
  };

  return (
    <form
      onSubmit={(e) => submitHandler(e)}
      className="contact-validation-active"
    >
      <div className="row">
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.name}
              type="text"
              name="name"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Your Name"
            />
            {validator.message("name", forms.name, "required|alpha_space")}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.email}
              type="email"
              name="email"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Your Email"
            />
            {validator.message("email", forms.email, "required|email")}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.phone}
              type="phone"
              name="phone"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Your phone"
            />
            {validator.message("phone", forms.phone, "required|phone")}
          </div>
        </div>
        {/* <div className="col col-lg-6 col-12">
          <div className="form-field">
            <select
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              value={forms.subject}
              type="text"
              name="subject"
            >
              <option>Web Design</option>
              <option>Web Development</option>
              <option>Ux/Ui Design</option>
            </select>
            {validator.message("subject", forms.subject, "required")}
          </div>
        </div> */}
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.city}
              type="text"
              name="city"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Your city"
            />
            {validator.message("city", forms.city, "required")}
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <textarea
            onBlur={(e) => changeHandler(e)}
            onChange={(e) => changeHandler(e)}
            value={forms.message}
            type="text"
            name="message"
            placeholder="Message"
          ></textarea>
          {validator.message("message", forms.message, "required")}
        </div>
      </div>
      <div className="submit-area">
        <button type="submit" className="theme-btn">
          {loading && <Loader />}Submit Now
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
