import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { currentUser } from "../../jotaiStore/user";
import { useAtom } from "jotai";
import { toast } from "react-toastify";
import { updateUser } from "../../services/user/service";

const EditModal = ({ editModal, setEditModal, changeHandler }) => {
  const [, setUser] = useAtom(currentUser);

  const updateUserFunction = () => {
    updateUser({ body: editModal })
      .then(() => {
        setUser(editModal);
        setEditModal({});
        toast.success("Profile Updated Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          role: "alert",
        });
      })
      .catch((err) => {
        toast.err(err?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          role: "alert",
        });
      });
  };
  return (
    <div>
      <Modal
        open={!!editModal?._id}
        showCloseIcon
        animationDuration={500}
        role="dialog"
        closeOnEsc
        closeOnOverlayClick
        onClose={() => {
          setEditModal({});
        }}
        center
      >
        <div className="rounded-md p-4">
          <div>
            <h2 className="text-xl font-semibold">Edit Profile</h2>
            <p className="text-gray-500">
              Update your profile information here.
            </p>
          </div>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  className="inputOutline"
                  fullWidth
                  placeholder="Full Name"
                  value={editModal?.name}
                  variant="outlined"
                  name="name"
                  label="Name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="inputOutline"
                  fullWidth
                  placeholder="Mobile Number"
                  value={editModal?.phone}
                  type="tel"
                  variant="outlined"
                  name="phone"
                  label="Mobile Number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => changeHandler(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled
                  className="inputOutline"
                  fullWidth
                  placeholder="E-mail"
                  value={editModal.email}
                  variant="outlined"
                  name="email"
                  label="E-mail"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => changeHandler(e)}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid className="formFooter">
                  <Button
                    fullWidth
                    className=""
                    style={{
                      backgroundColor: "#9e93e9",
                    }}
                    onClick={updateUserFunction}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default EditModal;
