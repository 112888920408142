import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { getEnrolledCourses } from "../../services/testPaper/service";
import { Loader2 } from "../../components/loader";
import SideBar from "./SideBar";
import Render from "./Render";
import { useAtom } from "jotai";
import NoData from "../../components/Nodata";
import { currentUser } from "../../jotaiStore/user";
const EnrolledCourses = () => {
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user] = useAtom(currentUser);
  const [selectedTab, setSelectedTab] = useState("");
  const courses = [
    {
      id: 0,
      name: "All",
      key: "",
    },
    {
      id: 1,
      name: "RCIC",
      key: "rcic",
    },
    // {
    //   id: 2,
    //   name: "RCIC - IRB ",
    //   key: "rcic-irb",
    // },
    // {
    //   id: 3,
    //   name: "Citizenship",
    //   key: "citizenship",
    // },
  ];
  const getCoursesData = () => {
    setLoading(true);
    const query = `?type=mock&courseName=${selectedTab}`;
    getEnrolledCourses(query)
      .then((res) => {
        setEnrolledCourses(res?.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (user?._id) getCoursesData();
  }, [user?._id, selectedTab]);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength) + "...";
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (!!localStorage.getItem("access_token") === false) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <Navbar />
      <PageTitle pageTitle="Enrolled Courses" pagesub="Enrolled Courses" />
      <div className="wpo-popular-area section-padding">
        <div className="container">
          <div className="wpo-popular-wrap">
            <div class="container-fluid">
              <div class="row d-flex justify-content-center">
                {user?._id ? (
                  <div class="col-sm-auto bg-light sticky-top py-10">
                    <SideBar
                      courses={courses}
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                    />
                  </div>
                ) : (
                  <></>
                )}

                <div class="col-sm row mt-5">
                  {!loading ? (
                    <div
                      className="row d-flex justify-content-start flex-wrap"
                      style={{
                        margin: "auto",
                      }}
                    >
                      {enrolledCourses?.data?.length ? (
                        enrolledCourses?.data?.map((course, idx) => (
                          <div
                            className="col col-lg-4 col-md-6 col-12  "
                            key={idx}
                          >
                            <Render
                              truncateText={truncateText}
                              course={course}
                              navigate={navigate}
                            />
                          </div>
                        ))
                      ) : (
                        <NoData
                          title="There are no enrolled courses"
                          description="Please enroll for a course to get started"
                        />
                      )}
                    </div>
                  ) : (
                    <Loader2 />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </>
  );
};

export default EnrolledCourses;
