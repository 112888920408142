import React, { useState } from "react";
import "../style.css";
import Quiz from "./Quiz";
import Result from "./Result";
const PracticePaper = ({
  lessonName,
  query,
  selectPracticePaper,
  getLessonsList,
  setGetLessonsList,
}) => {
  const [quizStart, setQuizStart] = useState(false);
  const [isFinished, setIsFinished] = useState({
    finished: false,
    resultArray: [],
  });
  return (
    <div>
      <div className="main lesson">
        <div className="lessonHeading">Practice Questions</div>
        <div className="section2 d-flex flex-md-row flex-column block align-items-start align-items-md-center">
          <div
            className="text1"
            style={{
              textTransform: "uppercase",
            }}
          >
            {query} {`>`}
          </div>
          <div
            className="text1 mx-0 mx-md-3"
            style={{
              textTransform: "uppercase",
            }}
          >
            {lessonName?.lessonName} {`>`}
          </div>
          <div>
            <div
              className="text1"
              style={{
                textTransform: "uppercase",
              }}
            >
              Practice Questions
            </div>
          </div>
        </div>
        <div>
          <div className="h2 mt-5">Practice Questions</div>
        </div>
        {!quizStart ? (
          <div
            className="w-50 w-md-25 mt-3"
            role="button"
            onClick={() => {
              setQuizStart(true);
            }}
          >
            <div className="inprogressbtn h5">Start quiz</div>
          </div>
        ) : (
          <>
            {isFinished?.resultArray?.length ? (
              <Result setIsFinished={setIsFinished} isFinished={isFinished} />
            ) : (
              <Quiz
                selectPracticePaper={selectPracticePaper}
                setIsFinished={setIsFinished}
                isFinished={isFinished}
                setGetLessonsList={setGetLessonsList}
                getLessonsList={getLessonsList}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PracticePaper;
