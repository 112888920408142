import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import loadingGif from "../../images/loading1.gif";
import "./style.scss";
import {
  forgotPassword,
  resetPassword,
  verifyOtp,
} from "../../services/auth/service";

const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState("forgotpass");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [value, setValue] = useState({});
  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    validator.showMessages();
  };
  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const forgotPasswordFunction = (type) => {
    forgotPassword({
      body: {
        email: value?.email,
      },
    })
      .then(() => {
        setLoading(false);
        setStep("otp");
        toast.success(`OTP  ${!!type ? "resend" : "sent"} successfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          role: "alert",
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          role: "alert",
        });
      });
  };
  const submitForm = (e) => {
    e?.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setLoading(true);
      forgotPasswordFunction();
    } else {
      validator.showMessages();
      toast.error("Empty field is not allowed!");
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = "";
        inputRefs?.[index - 1]?.current?.focus();
        return newOtp;
      });
    } else if (!!otp[index] === false) {
      if (otp?.length <= 4) {
        const newOtp = [...otp];
        newOtp[index] = event?.key;
        setOtp(newOtp);
        if (index < inputRefs.length - 1) inputRefs[index + 1].current.focus();
      }
    } else if (otp[index]) {
      if (index < inputRefs.length - 1) {
        const newOtp = [...otp];
        newOtp[index + 1] = event?.key;
        setOtp(newOtp);
        inputRefs[index + 1].current.focus();
      }
    }
  };

  return (
    <Grid className="loginWrapper">
      {step === "forgotpass" ? (
        <Grid className="loginForm">
          <h2>Forgot Password</h2>
          <p>Reset your account password</p>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  className="inputOutline"
                  fullWidth
                  placeholder="E-mail"
                  value={value.email}
                  variant="outlined"
                  name="email"
                  label="E-mail"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                />
                {validator.message("email", value.email, "required|email")}
              </Grid>
              <Grid item xs={12}>
                <Grid className="formFooter">
                  <Button
                    fullWidth
                    className="cBtn cBtnLarge cBtnTheme"
                    onClick={(e) => {
                      submitForm(e);
                    }}
                  >
                    {loading ? (
                      <img
                        src={loadingGif}
                        alt="loading"
                        style={{ width: 20, marginRight: 10 }}
                      />
                    ) : (
                      <></>
                    )}
                    Send OTP
                  </Button>
                </Grid>

                <p className="noteHelp">
                  Already have an account?{" "}
                  <Link to="/login">Return to Sign In</Link>
                </p>
              </Grid>
            </Grid>
          </form>
          <div className="shape-img">
            <i className="fi flaticon-honeycomb"></i>
          </div>
        </Grid>
      ) : (
        <></>
      )}
      {step === "otp" && (
        <Grid className="loginForm">
          <h2>Verify Otp</h2>
          <p>Enter the OTP sent to your email address.</p>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div>
                  <div className="w-100">
                    <div className="d-flex ">
                      {otp?.map((digit, index) => (
                        <input
                          className="text-center"
                          key={index}
                          type="text"
                          maxLength="1"
                          value={digit}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          ref={inputRefs[index]}
                          style={{
                            width: "25%",
                            marginInline: 10,
                          }}
                        />
                      ))}
                    </div>
                    <div
                      className="h6 text-end m-2"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        forgotPasswordFunction("resend");
                      }}
                    >
                      Resend Otp?
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid className="formFooter">
                  <Button
                    fullWidth
                    className="cBtn cBtnLarge cBtnTheme"
                    onClick={() => {
                      setLoading(true);
                      verifyOtp(btoa(`${value?.email}-${otp.join("")}`))
                        .then(() => {
                          setLoading(false);
                          setStep("resetpass");

                          toast.success("OTP verified successfully", {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            role: "alert",
                          });
                        })
                        .catch((err) => {
                          setLoading(false);

                          toast.error(err?.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            role: "alert",
                          });
                        });
                    }}
                  >
                    {loading ? (
                      <img
                        src={loadingGif}
                        alt="loading"
                        style={{ width: 20, marginRight: 10 }}
                      />
                    ) : (
                      <></>
                    )}
                    Verify OTP
                  </Button>
                </Grid>
                <p className="noteHelp">
                  Already have an account?{" "}
                  <Link to="/login">Return to Sign In</Link>
                </p>
              </Grid>
            </Grid>
          </form>
          <div className="shape-img">
            <i className="fi flaticon-honeycomb"></i>
          </div>
        </Grid>
      )}
      {step === "resetpass" && (
        <Grid className="loginForm">
          <h2>Reset Password</h2>
          <p>Enter the the password you want to use for your account.</p>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div>
                  <TextField
                    className="inputOutline"
                    fullWidth
                    placeholder="Password"
                    value={value.password}
                    variant="outlined"
                    name="password"
                    label="Enter Password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                  />
                  <TextField
                    className="inputOutline my-3"
                    fullWidth
                    placeholder="Confirm Password"
                    value={value.confirmPassword}
                    variant="outlined"
                    name="confirmPassword"
                    label="Enter Confirm Password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid className="formFooter">
                  <Button
                    fullWidth
                    className="cBtn cBtnLarge cBtnTheme"
                    onClick={() => {
                      if (value?.password !== value?.confirmPassword)
                        return alert(
                          "Password and confirm password should be same"
                        );

                      setLoading(true);
                      resetPassword(btoa(`${value?.email}-${value?.password}`))
                        .then(() => {
                          setLoading(false);
                          toast.success("Password reset successfully", {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            role: "alert",
                          });
                          navigate("/login");
                        })
                        .catch((err) => {
                          setLoading(false);

                          toast.error(err?.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            role: "alert",
                          });
                        });
                    }}
                  >
                    {loading ? (
                      <img
                        src={loadingGif}
                        alt="loading"
                        style={{ width: 20, marginRight: 10 }}
                      />
                    ) : (
                      <></>
                    )}
                    Save
                  </Button>
                </Grid>
                <p className="noteHelp">
                  Already have an account?{" "}
                  <Link to="/login">Return to Sign In</Link>
                </p>
              </Grid>
            </Grid>
          </form>
          <div className="shape-img">
            <i className="fi flaticon-honeycomb"></i>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default ForgotPassword;
