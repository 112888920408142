import React from "react";
import "../style.css";
import Lesson from "./Lesson";
import Topic from "./Topic";
import PracticePaper from "./PracticePaper";
const Main = ({
  selectedTopic,
  topicList,
  lessonName,
  getLessonsList,
  setGetLessonsList,
  setSelectedIndex,
  selectedIndex,
  setSelectedTopic,
  setSelectPracticePaper,
  selectPracticePaper,
  query,
}) => {
  return (
    <div className="main">
      {selectedTopic?.name && selectPracticePaper?.length === 0 ? (
        <Topic
          selectedTopic={selectedTopic}
          getLessonsList={getLessonsList}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
          setSelectedTopic={setSelectedTopic}
        />
      ) : (
        <></>
      )}
      {lessonName?.lessonName &&
      !selectedTopic?.name &&
      selectPracticePaper?.length === 0 ? (
        <Lesson
          lessonName={lessonName}
          topicList={topicList}
          getLessonsList={getLessonsList}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
          setSelectedTopic={setSelectedTopic}
          selectedTopic={selectedTopic}
          setSelectPracticePaper={setSelectPracticePaper}
          selectPracticePaper={selectPracticePaper}
        />
      ) : (
        <></>
      )}
      {selectPracticePaper?.length &&
      lessonName?.lessonName &&
      !selectedTopic?.name ? (
        <PracticePaper
          lessonName={lessonName}
          query={query}
          selectPracticePaper={selectPracticePaper}
          setSelectPracticePaper={setSelectPracticePaper}
          setGetLessonsList={setGetLessonsList}
          getLessonsList={getLessonsList}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Main;
