import React, { useState } from "react";
import { markAsComplete } from "../../../services/lesson/service";
import { currentUser } from "../../../jotaiStore/user";
import { useAtom } from "jotai";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const Footer = ({
  getLessonsList,
  setSelectedIndex,
  selectedIndex,
  setSelectedTopic,
  isCompleted,
  selectedTopic,
  topic,
}) => {
  const [user, setUser] = useAtom(currentUser);
  const [loading, setLoading] = useState("");
  return (
    <>
      <div className="footer  mt-5 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
        <div className="">
          <div
            className="inprogressbtn "
            role="button"
            onClick={() => {
              setSelectedTopic({});
              const getLessonIndex = getLessonsList?.lessons?.findIndex(
                (i) => i?._id === selectedIndex
              );
              const getNextLesson =
                getLessonsList?.lessons?.[getLessonIndex - 1];

              if (getNextLesson) {
                setSelectedIndex(getNextLesson?._id);
              }
            }}
          >
            <div className="d-flex align-items-center">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="white"
                  class="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                </svg>
              </div>
              <div className="mx-2">Previous Lesson</div>
            </div>
          </div>
        </div>
        {!isCompleted ? (
          <div>
            <div
              className="inprogressbtn my-3 my-md-0"
              role="button"
              onClick={() => {
                setLoading("border");

                markAsComplete({
                  body: {
                    topicId: selectedTopic?._id,
                  },
                })
                  .then(() => {
                    setLoading("");
                    setUser({
                      ...user,
                      topics: [...(user?.topics || []), selectedTopic?._id],
                    });
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    if (topic) {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      const lessonData = getLessonsList?.lessons?.find(
                        (i) => i?._id === selectedIndex
                      );

                      const topicIndex = lessonData?.topics?.findIndex(
                        (i) => i?._id === selectedTopic?._id
                      );
                      const nextTopic = lessonData?.topics?.[topicIndex + 1];
                      if (nextTopic) {
                        setSelectedTopic(nextTopic);
                      } else {
                        setSelectedTopic({});
                      }
                    } else {
                      setSelectedTopic({});

                      const getLessonIndex = getLessonsList?.lessons?.findIndex(
                        (i) => i?._id === selectedIndex
                      );
                      const getNextLesson =
                        getLessonsList?.lessons?.[getLessonIndex + 1];

                      if (getNextLesson) {
                        setSelectedIndex(getNextLesson?._id);
                      }
                    }
                    toast.success("Marked as complete", {
                      position: "top-right",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  })
                  .catch(() => {
                    setLoading();
                  });
              }}
            >
              <div className="d-flex align-items-center">
                <div className="mx-2"></div>

                <div className="d-flex align-items-center">
                  <Spinner
                    as="span"
                    animation={loading}
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <div className="mx-2">Mark as complete</div>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-check2-all"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div>
          <div
            className="inprogressbtn"
            role="button"
            onClick={() => {
              if (topic) {
                window.scrollTo({ top: 0, behavior: "smooth" });
                const lessonData = getLessonsList?.lessons?.find(
                  (i) => i?._id === selectedIndex
                );

                const topicIndex = lessonData?.topics?.findIndex(
                  (i) => i?._id === selectedTopic?._id
                );
                const nextTopic = lessonData?.topics?.[topicIndex + 1];
                if (nextTopic) {
                  setSelectedTopic(nextTopic);
                } else {
                  setSelectedTopic({});
                }
              } else {
                setSelectedTopic({});

                const getLessonIndex = getLessonsList?.lessons?.findIndex(
                  (i) => i?._id === selectedIndex
                );
                const getNextLesson =
                  getLessonsList?.lessons?.[getLessonIndex + 1];

                if (getNextLesson) {
                  setSelectedIndex(getNextLesson?._id);
                }
              }
            }}
          >
            <div className="d-flex align-items-center">
              <div className="mx-2">Next {topic ? "Topic" : "Lesson"}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-chevron-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
