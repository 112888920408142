import cimg1 from "../images/category/img-1.jpg";
import cimg2 from "../images/category/img-2.jpg";
import cimg3 from "../images/category/img-3.jpg";
import cimg4 from "../images/category/img-4.jpg";
import cimg5 from "../images/category/img-5.jpg";
import cimg6 from "../images/category/img-6.jpg";

const CoursesCategory = [
  {
    Id: "1",
    cImg: cimg1,
    thumb: "flaticon-user-experience",
    title: "CICC - EPE EXAM",
    slug: "CICC - EPE EXAM",
    description:
      "The Entry-to-Practice Exam (EPE) tests Regulated Canadian Immigration Consultant (RCIC) candidates on their knowledge, skills, and judgment related to immigration and citizenship matters.",
  },
  {
    Id: "2",
    cImg: cimg2,
    thumb: "flaticon-megaphone",
    title: "RCIC - IRB Class of licence",
    slug: "RCIC - IRB Class of licence",
    description:
      "Once you complete a Specialization Program pathway, register for the Specialization Exam. If you pass the Specialization Exam, you will be issued the RCIC- IRB class of licence which will authorize you to practise before the IRB.",
  },
  {
    Id: "3",
    cImg: cimg3,
    thumb: "flaticon-code",
    title: "Citizenship Exam",
    slug: "Citizenship ",
    description:
      "This package contains all the resources, data, preparation, and tools that you need to easily clear your Entry to Practice (EPE), RCIC Consultant & CICC, entry to practice Canadian immigration 2023 exams.",
  },
  // {
  //    Id: '4',
  //    cImg: cimg4,
  //    thumb: 'flaticon-knowledge',
  //    title: 'Self Improvement',
  //    slug: 'Self-Improvement',
  //    description: 'We are providing you the best Self Improvement guideline. That help you be professional.'
  // },
  // {
  //    Id: '5',
  //    cImg: cimg5,
  //    thumb: 'flaticon-video-lesson',
  //    title: 'Video Editing',
  //    slug: 'Video-Editing',
  //    description: 'We are providing you the best Video Editing guideline for you. That help you be professional.'
  // },
  // {
  //    Id: '6',
  //    cImg: cimg6,
  //    thumb: 'flaticon-paint-palette',
  //    title: 'Graphic Design',
  //    slug: 'Graphic-Design',
  //    description: 'We are providing you the best Graphic Design guideline. That help you be professional.'
  // },
];

export default CoursesCategory;
