/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Logo from "../../images/logo-trans.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./styles.css";
import { ModalBox } from "./ModalBox";
import { getAttemptedPaperCount } from "../../services/examAttempt/service";
const Rules = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const path = window.location.pathname;
  const [count, setCount] = useState(5);
  const [startCounter, setStartCounter] = useState(false);
  const [open, setOpen] = useState(false);
  const [getAttemptedCount, setGetAttemptedCount] = useState(0);
  const firstColumnList = [
    {
      id: 1,
      name: "Stay Seated",
      icon: "https://cdn.icon-icons.com/icons2/2645/PNG/512/mic_mute_icon_159965.png",
    },
    {
      id: 2,
      name: "Stay In Frame",

      icon: "https://cdn.icon-icons.com/icons2/2645/PNG/512/mic_mute_icon_159965.png",
    },
    {
      id: 3,
      name: "Stay Quiet",
      icon: "https://cdn.icon-icons.com/icons2/2645/PNG/512/mic_mute_icon_159965.png",
    },
    {
      id: 4,
      name: "Stay in Private Room",
      icon: "https://cdn.icon-icons.com/icons2/2645/PNG/512/mic_mute_icon_159965.png",
    },
  ];

  const ruleList = [
    {
      id: 1,
      rule: "Please make sure you save and close all browser tabs",
    },
    {
      id: 2,
      rule: "You will not be allowed to copy text and images during the exam",
    },
    {
      id: 3,
      rule: "You will not be able to switch applications during the exam",
    },
  ];
  const routePath = path?.includes("sample")
    ? `/testPaper//${id}?type=sample`
    : `/testPaper/${id}`;

  const getAttemptedTestCount = () => {
    getAttemptedPaperCount(id).then((res) => {
      setGetAttemptedCount(res);
    });
  };
  useEffect(() => {
    getAttemptedTestCount();
  }, []);
  return (
    <div
      className=""
      style={{
        height: "100vh",
        overflow: "scroll",
      }}
    >
      <div class="">
        <div class="row ">
          <div class="col">
            <Link
              onClick={() => {
                navigate("/home");
              }}
              className="navbar-brand"
              to="/home"
            >
              <img
                src={
                  "https://assets.onecompiler.app/42zmnshnj/4358f35k9/download.png"
                }
                alt="logo"
                className="h-75 max-w-75"
              />
            </Link>
          </div>
          <div
            class="col-6 h1  align-middle mh-100  "
            style={{
              height: "100px",
            }}
          >
            <p className=" text-center h4 mt-4">Exam Rule Check</p>
          </div>
          <div class="col text-end mt-4 fw-10" role="button">
            <div>
              <div className="d-flex justify-content-center">
                <ModalBox
                  id={id}
                  routePath={routePath}
                  testPaperDetails
                  setStartCounter={setStartCounter}
                  startCounter={startCounter}
                  count={count}
                  setCount={setCount}
                  open={open}
                  setOpen={setOpen}
                  getAttemptedCount={getAttemptedCount}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row h-100">
          <div class="col-md-4 col-xs-12   ">
            <>
              <div className="h-75 border">
                <div className="  p-0 border-bottom">
                  <div className="p-2 d-flex  ">Guidelines</div>
                </div>
                <div className=" p-0">
                  <div class="container p-2 border-top p-4">
                    <div class="row">
                      {firstColumnList.map((item) => (
                        <div
                          class="col-12 w-50 d-flex align-items-center justify-content-center"
                          key={item?.id}
                        >
                          <div class=" d-flex flex-column justify-content-center align-content-center">
                            <img
                              src={item?.icon}
                              className="h-25 w-25  align-self-center "
                            />
                            <p className="text-center h6 my-3">{item?.name}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className=" border  p-0 mt-4">
                <div className="p-2 d-flex  ">Accommodations</div>
                <div className="border-top p-3 h6">
                  2BRCIC will verify any accommodations you have been approved
                </div>
              </div>
            </>
          </div>
          <div class="col-md-4 col-xs-12  ">
            <>
              <div className="h-75 border">
                <div className="  p-0 border-bottom">
                  <div className="p-2 d-flex  ">Rules</div>
                </div>
                <div className=" ">
                  <div className="h6 px-2 pt-3">
                    Please review the following carefully
                  </div>
                  <div className="p-0 my-4">
                    <ul>
                      {ruleList.map((item) => (
                        <li className="h6 ">{item?.rule}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className=" border p-0  mt-4">
                <div className="   p-0 ">
                  <div className="p-2 d-flex  ">Breaks</div>
                  <div className="border-top p-3 h6">
                    Your are allowed breaks during the exam
                  </div>
                </div>
              </div>
            </>
          </div>

          <div class="col-md-4 col-xs-12 ">
            <>
              <div className="h-75 border ">
                <div className="  p-0 border-bottom">
                  <div className="p-2 d-flex  ">Allowed Resources</div>
                </div>
                <div className="">
                  <div className="h6 px-4 pt-3">
                    Your instructor will confirm the allowed external resources
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rules;
