import { DataService } from "../../utils/dataService/dataService";

const addPayment = async (id) => {
  try {
    const response = await DataService.post(`/stripe/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export { addPayment };
