import React, { useState } from "react";
import { Link } from "react-router-dom";
import Courses from "../../api/Courses";
import { useAtom } from "jotai";
import { currentUser } from "../../jotaiStore/user";
import { addPayment } from "../../services/stripe/service";
import { toast } from "react-toastify";
import Modal from "react-responsive-modal";
import SignUpPage from "../../main-component/SignUpPage";
import { Button } from "@mui/material";
import loadingGif from "../../images/loading1.gif";
import { Spinner } from "react-bootstrap";

const CourseSectionS3 = (props) => {
  const [user] = useAtom(currentUser);
  const [loading, setLoading] = useState({
    loading: false,
    id: "",
  });
  const [modal, setModal] = useState("");

  const addCourseFun = (id) => {
    setLoading({
      loading: "border",
      id: id,
    });
    addPayment(id)
      .then((res) => {
        setLoading({});
        window.location.replace(res?.data?.session?.url);
      })
      .catch((err) => {
        setLoading({});
        toast.error(err?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          role: "alert",
        });
      });
  };
  return (
    <div className={`wpo-popular-area section-padding ${props.pClass}`}>
      <Modal
        open={modal}
        animationDuration={500}
        onClose={() => {
          setModal("");
        }}
        center
        styles={{
          modal: {
            maxWidth: "500px",
            width: "100%",
            padding: 0,
            borderRadius: "10px",
            margin: 0,
          },
        }}
      >
        <SignUpPage
          setModal={setModal}
          modal={modal}
          addCourseFun={addCourseFun}
        />
      </Modal>
      <div className="container">
        <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
          CICC - EPE EXAM (PLANS)
        </h1>
        <div className="wpo-popular-wrap">
          <div className="row mt-5">
            {Courses.slice(0, 3).map((course) => (
              <div
                className="col col-lg-4 col-md-6 col-12 my-2"
                key={course?._id}
              >
                <div className="wpo-popular-single h-100">
                  <div className="wpo-popular-item h-100">
                    <div className="wpo-popular-img">
                      {/* <img src={course.cImg} alt="" /> */}
                      <div className="thumb">
                        <span>${course.fee}</span>
                      </div>
                    </div>
                    <div className="h-100">
                      <div className="wpo-popular-text-top">
                        <div className="text-center h3 fw-bold text-uppercase text-black">
                          {course.authortitle}
                        </div>
                      </div>

                      <div
                        style={{
                          padding: "15px 20px",
                          height: "100%",
                          backgroundColor: "#fff",
                        }}
                      >
                        <ul className="h2">
                          <li>{course.l1}</li>
                          <li>{course.l2}</li>
                          {course.l3 && <li>{course.l3}</li>}
                          {course.l4 && <li>{course.l4}</li>}
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center bg-primary rounded-bottom rounded-end">
                      <div
                        className="d-flex align-items-center "
                        role="button"
                        onClick={() =>
                          user?._id
                            ? addCourseFun(course?.Id)
                            : setModal(course?.Id)
                        }
                      >
                        {loading?.id === course?.Id ? (
                          <Spinner
                            as="span"
                            animation={loading?.loading}
                            size="lg"
                            role="status"
                            aria-hidden="true"
                            variant="light"
                          />
                        ) : (
                          <></>
                        )}
                        <div className="mx-2 h5 text-uppercase font-weight-bold text-white py-2 mt-1">
                          Enroll Now
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSectionS3;
