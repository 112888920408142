import cimg1 from "../images/popular/rcic.png";

import cSimg1 from "../images/popular/popular-single/img-1.jpg";
import cSimg2 from "../images/popular/popular-single/img-2.jpg";
import cSimg3 from "../images/popular/popular-single/img-3.jpg";
import author1 from "../images/popular/avater/img-1.jpg";
import author2 from "../images/popular/avater/img-2.jpg";
import author3 from "../images/popular/avater/img-3.jpg";

const Courses = [
  {
    Id: "1",
    cImg: cimg1,
    cSImg: cSimg1,
    author: author1,
    authortitle: "PREMIUM",
    lesson: "20",
    fee: "600",
    student: "200",
    ratting: "4.5",
    l1: "3 Mock Test (2 Attempts Each)",
    l2: "Full Course (Covering all 9 Competency)",
    l3: "Additional Resources",
    l4: "Practice question for each Competency",
    title:
      "The Entry-to-Practice Exam (EPE) tests Regulated Canadian Immigration Consultant (RCIC) candidates on their knowledge, skills, and judgment related to immigration and citizenship matters.",
    slug: "Learn-WordPress-&-Elementor-for-Beginners",
    description:
      "The Entry-to-Practice Exam (EPE) tests Regulated Canadian Immigration Consultant (RCIC) candidates on their knowledge, skills, and judgment related to immigration and citizenship matters.",
  },
  {
    Id: "2",
    cImg: cimg1,
    cSImg: cSimg2,
    author: author2,
    authortitle: "Standard",
    lesson: "30",
    fee: "500",
    student: "420",
    ratting: "5.0",
    l1: "3 Mock Test (2 Attempts Each)",
    l2: "Additional Resources",
    l3: "Practice question for each Competency",
    l4: "",
    title:
      "Once you complete a Specialization Program pathway, register for the Specialization Exam. If you pass the this Exam, you will be issued the RCIC- IRB class of licence which will authorize you to practice before the IRB.",
    slug: "Once you complete a Specialization Program pathway, register for the Specialization Exam. If you pass the this Exam, you will be issued the RCIC- IRB class of licence which will authorize you to practice before the IRB.",
    description:
      "Once you complete a Specialization Program pathway, register for the Specialization Exam. If you pass the this Exam, you will be issued the RCIC- IRB class of licence which will authorize you to practice before the IRB.",
  },
  {
    Id: "3",
    cImg: cimg1,
    cSImg: cSimg3,
    author: author3,
    authortitle: "Basic",
    lesson: "25",
    fee: "450",
    student: "450",
    ratting: "4.8",
    l1: "5 Mock Test (3 Attempts Each)",
    l2: "Additional Resources",
    l3: "",
    l4: "",
    title:
      "This package contains all the resources, data, preparation, and tools that you need to easily clear your Entry to Practice (EPE), RCIC Consultant & CICC, entry to practice Canadian immigration 2023 exams.",
    slug: "This package contains all the resources, data, preparation, and tools that you need to easily clear your Entry to Practice (EPE), RCIC Consultant & CICC, entry to practice Canadian immigration 2023 exams.",
    description:
      "This package contains all the resources, data, preparation, and tools that you need to easily clear your Entry to Practice (EPE), RCIC Consultant & CICC, entry to practice Canadian immigration 2023 exams.",
  },
  // {
  //   Id: "2",
  //   cImg: cimg2,
  //   cSImg: cSimg2,
  //   author: author2,
  //   authortitle: "RCIC - IRB Class of licence",
  //   lesson: "30",
  //   fee: "300",
  //   student: "420",
  //   ratting: "5.0",
  //   l1: "3 Mock Test",
  //   l2: "Cover core competencies",
  //   l3: "Full length exams",
  //   title:
  //     "Once you complete a Specialization Program pathway, register for the Specialization Exam. If you pass the this Exam, you will be issued the RCIC- IRB class of licence which will authorize you to practice before the IRB.",
  //   slug: "Once you complete a Specialization Program pathway, register for the Specialization Exam. If you pass the this Exam, you will be issued the RCIC- IRB class of licence which will authorize you to practice before the IRB.",
  //   description:
  //     "Once you complete a Specialization Program pathway, register for the Specialization Exam. If you pass the this Exam, you will be issued the RCIC- IRB class of licence which will authorize you to practice before the IRB.",
  // },
  // {
  //   Id: "3",
  //   cImg: cimg3,
  //   cSImg: cSimg3,
  //   author: author3,
  //   authortitle: "Citizenship Exam",
  //   lesson: "25",
  //   fee: "50",
  //   student: "300",
  //   ratting: "4.8",
  //   l1: "3 Mock Test",
  //   l2: "Up-To-Date Test Questions",
  //   l3: "An Effective Study Process",
  //   title:
  //     "This package contains all the resources, data, preparation, and tools that you need to easily clear your Entry to Practice (EPE), RCIC Consultant & CICC, entry to practice Canadian immigration 2023 exams.",
  //   slug: "This package contains all the resources, data, preparation, and tools that you need to easily clear your Entry to Practice (EPE), RCIC Consultant & CICC, entry to practice Canadian immigration 2023 exams.",
  //   description:
  //     "This package contains all the resources, data, preparation, and tools that you need to easily clear your Entry to Practice (EPE), RCIC Consultant & CICC, entry to practice Canadian immigration 2023 exams.",
  // },
  // {
  //    Id: '4',
  //    cImg: cimg4,
  //    cSImg: cSimg4,
  //    author:author1,
  //    authortitle:'Takla Beshu',
  //    lesson:'30',
  //    fee: '120',
  //    student:'730',
  //    ratting:'4.9',
  //    title: 'Learn WordPress & Elementor for Beginners',
  //    slug: 'Learn-WordPress-&-Elementor-for-Beginner',
  //    description: 'We are providing you the best Self Improvement guideline. That help you be professional.'
  // },
  // {
  //    Id: '5',
  //    cImg: cimg5,
  //    cSImg: cSimg5,
  //    author:author2,
  //    authortitle:'william taklu',
  //    lesson:'50',
  //    fee: '50',
  //    student:'380',
  //    ratting:'4.2',
  //    title: 'The Complete Guide to Be a Graphics Designer.',
  //    slug: 'The-Complete-Guide-to-Be-a-Graphics',
  //    description: 'We are providing you the best Video Editing guideline. That help you be professional.'
  // },
  // {
  //    Id: '6',
  //    cImg: cimg6,
  //    cSImg: cSimg6,
  //    author:author3,
  //    authortitle:'Bibi Rela',
  //    lesson:'70',
  //    fee: '50',
  //    student:'540',
  //    ratting:'4.3',
  //    title: 'Learning How To Write As A Professional Author',
  //    slug: 'Learning-How-To-Write-As-A-Professional',
  //    description: 'We are providing you the best Video Editing guideline. That help you be professional.'
  // }
];

// const Courses = [
//   {
//       id: 1,
//       svg: Svg(1),
//       btn: linkSVG(1),
//       name: 'Mock tests- EPE- RCIC',
//       noOfTest: 3,
//       text1: `Mock Tests - EPE -RCIC package helps you to
//       practice real scenario questions to test your
//       knowledge on all important topics. There are 3
//       mock tests, each mock test consists of 135
//       questions and each test is of 3 Hours.`,
//       queryType: 'MOCK TEST',
//   },
//   {
//       id: 2,
//       name: 'EPE-RCIC- Entry to Practise Exam',
//       noOfTest: 3,
//       svg: Svg(2),
//       btn: linkSVG(2),
//       text1: ` This course to EPE- RCIC Package includes
//        detailed information about all major topics
//        followed by practice questions for every single
//        topic. You will also find 3 full-length mock test
//       in this package
//       `,
//       queryType: 'RCIC - EPE BUNDLE PACK',
//   },
//   {
//       id: 3,
//       name: 'Live Sessions- EPE (RCIC)',
//       noOfTest: 5,
//       svg: Svg(3),
//       btn: linkSVG(3),
//       text1: ` Series of live session from different experts
//        will help you to overview all major topics which
//        you require to pass your entry to practice exami
//       forRCIC`,
//       queryType: 'RCIC - EPE BUNDLE PACK',
//   },
// ];

export default Courses;
