import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Hero from "../../components/hero/hero";
import Hero2 from "../../components/hero2/Hero2";
import About from "../../components/about/about";
import About1 from "../../components/about/about";
import Features from "../../components/Features/Features";
import CategorySection from "../../components/CategorySection/CategorySection";
import CourseSection from "../../components/CourseSection/CourseSection";
import Testimonial from "../../components/Testimonial/Testimonial";
import TeamSection from "../../components/TeamSection/TeamSection";
import ChooseSection from "../../components/ChooseSection/ChooseSection";
import CategorySection2 from "../../components/CategorySection2/CategorySection2";
import BlogSection from "../../components/BlogSection/BlogSection";
import Newslatter from "../../components/Newslatter/Newslatter";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import ChooseSectionS2 from "../../components/ChooseSectionS2/ChooseSectionS2";
import Newslatter2 from "../../components/Newslatter2/Newslatter2";

const HomePage = () => {
  return (
    // <Fragment>
    //     <Navbar hclass={'wpo-header-style-4'}/>
    //     <Hero/>
    //     <About/>
    //     <CategorySection/>
    //     <CourseSection/>
    //     <Testimonial/>
    //     <TeamSection pbClass={'pb-big'}/>
    //     <ChooseSection/>
    //     <BlogSection/>
    //     <Newslatter/>
    //     <Footer/>
    //     <Scrollbar/>
    // </Fragment>
    <Fragment>
      <Navbar hclass={"wpo-header-style-2"} topbarClass={"d-block"} />
      <Hero2 />
      <Features />
      <About1 />
      {/* <About2/> */}
      <CategorySection2 />
      {/* <CourseSection /> */}
      <Testimonial />
      {/* <ChooseSectionS2 />
      <Newslatter2 />
      <BlogSection /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
