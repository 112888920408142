import React from "react";
import Footer from "./Footer";
import { currentUser } from "../../../jotaiStore/user";
import { useAtom } from "jotai";

const Topic = ({
  selectedTopic,
  getLessonsList,
  setSelectedIndex,
  selectedIndex,
  setSelectedTopic,
}) => {
  const [user] = useAtom(currentUser);
  const isCompleted = user?.topics?.includes(selectedTopic?._id);
  return (
    <div className="main lesson">
      <div className="lessonHeading">{selectedTopic?.name}</div>

      <div className="section">
        <div
          className="text1"
          style={{
            textTransform: "uppercase",
          }}
        >
          {selectedTopic?.name}
        </div>
        <div
          className="inprogressbtn mt-4 mt-md-0 "
          style={{
            textTransform: "uppercase",
          }}
        >
          {isCompleted ? "Completed" : "In Progress"}
        </div>
      </div>
      <div
        style={{
          marginTop: 30,
        }}
        className="text-2xl text-black"
      >
        <div
          style={{}}
          // className={style.htmlContent}
          dangerouslySetInnerHTML={{
            __html: selectedTopic?.description,
          }}
        />
      </div>

      <Footer
        isCompleted={user?.topics?.includes(selectedTopic?._id)}
        getLessonsList={getLessonsList}
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
        setSelectedTopic={setSelectedTopic}
        selectedTopic={selectedTopic}
        topic
      />
    </div>
  );
};

export default Topic;
