import { DataService } from "../../utils/dataService/dataService";

const addContactUs = async ({ body }) => {
  try {
    const response = await DataService.post(`/contactUs/`, body);
    return response;
  } catch (err) {
    throw err;
  }
};

export { addContactUs };
