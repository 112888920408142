import React from "react";
import "./style.css";
import { useAtom } from "jotai";
import { currentUser } from "../../jotaiStore/user";
import { useNavigate } from "react-router-dom";
const SideBar = ({ data, selectedTab }) => {
  const [user, setUser] = useAtom(currentUser);
  const navigate = useNavigate();
  return (
    <div className="gradient-box">
      <div className="d-flex p-3 flex-column flex-row flex-nowrap bg-white align-items-center sticky-top ">
        <div className="d-flex align-items-center p-2">
          <div className="text-secondary fw-bold h6">WELCOME</div>
          <div className="text-center text-secondary mx-2  fw-bold h6">
            {user?.name}
          </div>
        </div>
        <ul className="nav nav-pills nav-flush  flex-column  w-100 justify-content-between">
          {data?.map((item, idx) => (
            <div
              key={item?.name}
              className="my-2 d-flex align-items-center text-left py-2"
              style={{
                cursor: "pointer",
                color: selectedTab === item?.name ? "#2f57ef" : "#6a7385",
                fontSize: "17px",
                fontWeight: selectedTab === item?.name ? "bold" : "normal",
              }}
              onClick={() => {
                if (item?.name === "Logout") {
                  localStorage.clear();
                  setUser({});
                  navigate("/");
                } else {
                  navigate(item?.route);
                }
              }}
            >
              <i
                className={item?.icon}
                style={{
                  fontWeight: selectedTab === item?.name ? "bold" : "normal",
                }}
              ></i>
              <div className="mx-3">{item?.name}</div>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
