import React from "react";
import style from "./index.module.css";

export function Loader() {
  return (
    <>
      <div className={style?.threeBody}>
        <div className={style?.threeBodyDot}></div>
        <div className={style?.threeBodyDot}></div>
        <div className={style?.threeBodyDot}></div>
      </div>
    </>
  );
}

export function Loader2() {
  return (
    <>
      <div className={style.loader2}>
        <div className={style?.wrapper}>
          <div className={style?.circle}></div>
          <div className={style?.circle}></div>
          <div className={style?.circle}></div>
          <div className={style?.shadow}></div>
          <div className={style?.shadow}></div>
          <div className={style?.shadow}></div>
        </div>
      </div>
    </>
  );
}
