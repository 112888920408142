import React from "react";
import Layout from "./Layout";
import "./style.css";
const Dashboard = ({ data }) => {
  return (
    <Layout title={"Dashboard"}>
      <div className="d-flex flex-column flex-sm-row justify-content-evenly align-items-center gap-4   ">
        {data?.map((i) => {
          return (
            <div
              role="button"
              onClick={i?.onClick}
              key={i.id}
              className="d-flex w-100 flex-column p-4 justify-content-between h-100 align-items-center"
              style={{
                backgroundColor: i.color,
                borderWidth: 1,
                borderColor: "#ccc",
                borderRadius: 10,
              }}
            >
              <div
                class="iconCard d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: i.iconbgColor,
                  width: 80,
                  height: 80,
                  borderRadius: "50%",
                }}
              >
                <i
                  className={`${i.icon} h3 fw-bold`}
                  style={{
                    color: i.valueColor,
                  }}
                ></i>
              </div>

              <div
                style={{
                  fontSize: 60,
                  fontWeight: "bold",
                  color: i.valueColor,
                  marginBlock: 20,
                }}
              >
                {i.value}
              </div>
              <div
                className="card-title h6 fw-bold"
                style={{
                  color: i.textColor,
                  textTransform: "uppercase",
                }}
              >
                {i.name}
              </div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default Dashboard;
