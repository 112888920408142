import { DataService } from "../../utils/dataService/dataService";

const getSingleTestPaperDetails = async (endPoint) => {
  try {
    const response = await DataService.get(endPoint);
    return response;
  } catch (err) {
    throw err;
  }
};

export { getSingleTestPaperDetails };
