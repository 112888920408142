import React from "react";
import Layout from "./Layout";
import { currentUser } from "../../jotaiStore/user";
import { useAtom } from "jotai";
const MyProfile = ({ setEditModal }) => {
  const [user] = useAtom(currentUser);

  return (
    <Layout title={"My Profile"}>
      <div>
        <div className="d-flex flex-column flex-sm-row justify-content-evenly align-items-center gap-4">
          <div
            className="d-flex w-100 flex-column p-4 justify-content-between h-100 align-items-center"
            style={{
              backgroundColor: "#f5f5f5",
              borderWidth: 1,
              borderColor: "#ccc",
              borderRadius: 10,
            }}
          >
            <div
              class="layout d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#f5f5f5",
                width: 100,
                height: 100,
                borderRadius: "50%",
              }}
            >
              <i className={`ti-user h1`}></i>
            </div>
            <div
              style={{
                fontSize: 50,
                fontWeight: "bold",
                color: "#000",
                marginBlock: 30,
              }}
            >
              {user?.name}{" "}
            </div>
            <div
              className="card-title h6 fw-bold"
              style={{
                color: "#000",
                textTransform: "",
              }}
            >
              {user?.email}{" "}
              <i
                className="ti-pencil h5 text-danger"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEditModal(user);
                }}
              ></i>
            </div>
            <div
              className="card-title h6 fw-bold"
              style={{
                color: "#000",
                textTransform: "uppercase",
              }}
            >
              {user?.phone}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MyProfile;
