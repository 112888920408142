/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "../style.css";
import { currentUser } from "../../../jotaiStore/user";
import { useAtom } from "jotai";

const DisplayEnrolledCourse = ({ lesson, enrolledCourses }) => {
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength) + "...";
    }
  };
  const [user] = useAtom(currentUser);
  const attempts = user?.packageBought === "rcic-basic" ? 3 : 2;
  return (
    <>
      <div className="container m-0 p-0">
        <div className="row g-4 ">
          {enrolledCourses?.data?.map((i) => {
            return (
              <div className=" layout col-lg-4 col-12 ">
                <div className="">
                  <img
                    src={i?.imageUrl}
                    alt=""
                    className=" rounded-start rounded-end rounded-top"
                    style={{
                      height: 200,
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                  <div className="card-body">
                    <h5 className="card-title text-uppercase">
                      {i?.name}{" "}
                      <span
                        style={{
                          fontSize: 14,
                          color: "#2f57ef",
                        }}
                      >
                        ({i?.courseName})
                      </span>
                    </h5>
                    <div className="card-text">
                      <div className="h6 my-3">
                        {truncateText(i?.testDescription, 20)}
                      </div>
                      <div className="d-flex justify-content-between my-3">
                        <div
                          className=" text-uppercase"
                          style={{
                            fontSize: 13,
                            fontWeight: "bold",
                            color: "#8c919a",
                          }}
                        >
                          Attempts
                        </div>
                        <div
                          className=""
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#2f57ef",
                          }}
                        >
                          {attempts - i?.attemptFinished}{" "}
                          <span className="text-secondary fw-normal">
                            {`left${
                              attempts - i?.attemptFinished < 2 ? "" : "s"
                            }`}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div
                    
                      className="text-primary text-center  py-2 my-2"
                      style={{
                        background: "#e4e9fe",
                        borderRadius: 5,
                        fontWeight: "400",
                        textTransform: "uppercase",
                        fontSize: 14,
                      }}
                    >
                      Download Certificate
                    </div> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default DisplayEnrolledCourse;
