import React from "react";

const SideBar = ({ courses, setSelectedTab, selectedTab }) => {
  return (
    <div class="d-flex  flex-sm-column flex-row flex-nowrap bg-light align-items-center sticky-top ">
      <ul class="nav nav-pills nav-flush flex-sm-column flex-row flex-wrap mb-auto  justify-content-between  px-3  ">
        {courses?.map((course, idx) => (
          <div
            key={course?.id}
            className="my-2 mx-3  text-center px-3 py-2"
            style={{
              cursor: "pointer",
              backgroundColor: selectedTab === course?.key ? "#F2BC00" : "",
              color: selectedTab === course?.key ? "white" : "",
              borderRadius: "25px",
              fontSize: "20px",
            }}
            onClick={() => {
              setSelectedTab(course?.key);
            }}
          >
            <div>{course?.name}</div>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;
