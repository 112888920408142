import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import {
  currentUserService,
  verifyUserEmailService,
} from "../../services/user/service";
const VerifyUserEmail = () => {
  const [loading, setLoading] = useState(true);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const navigate = useNavigate();
  useEffect(() => {
    if (query.get("vt")) {
      verifyUserEmailService({
        token: query.get("vt"),
      })
        .then((res) => {
          setLoading(false);
          currentUserService();
          console.log(res);
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);
        });
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div
      className="d-flex  align-items-center justify-content-center"
      style={{
        height: "100vh",
      }}
    >
      {loading ? (
        <div>
          <div className="d-flex  align-items-center justify-content-center">
            <svg version="1.0" width="64px" height="64px" viewBox="0 0 128 128">
              <g transform="translate(0,128) scale(1,-1)">
                <path
                  d="M64 127.75A63.76 63.76 0 0 1 52.8 1.23v14.23a49.8 49.8 0 1 0 22.4 0V1.23A63.76 63.76 0 0 1 64 127.75z"
                  fill="#F68B1F"
                />
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 64 64"
                  to="360 64 64"
                  dur="1260ms"
                  repeatCount="indefinite"
                ></animateTransform>
              </g>
            </svg>
          </div>
          <h2 className="text-center text-black text-sm font-semibold mt-3">
            Wait...
          </h2>
          <p className="w-full text-center text-black">
            This may take a few seconds, please don't close this tab.
          </p>
        </div>
      ) : (
        <div className="main-container">
          <div className="check-container">
            <div class="verifyContainer">
              <div class="circle-border"></div>
              <div class="circle">
                <div class="success"></div>
              </div>
            </div>
          </div>

          <p className="w-full text-center text-black h1">
            Account verified successfully.
          </p>

          <div className="fw-bold h3 text-center mt-4">
            Wait a moment, you will be redirected to the home page.
          </div>
          <p className="w-full text-center text-gray-700 text-sm py-3 h5">
            Thank you for validating your email address.
          </p>
        </div>
      )}
    </div>
  );
};

export default VerifyUserEmail;
