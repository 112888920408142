import { getSingleTestPaperDetails } from "../../services/testDetails/services";
import React, { useEffect, useState } from "react";
import { QuestionsModule } from "./QuestionsModule";
import { updateExamPaper } from "../../services/examAttempt/service";
import { AnswerModule } from "./AnswerModule";
import { Loader4 } from "./Loader4";
import { useAtom } from "jotai";
import { examPaper, reloadModalAtom } from "../../jotaiStore/exam";
import { useNavigate } from "react-router-dom";

export function TestPaperModule() {
  const path = window?.location?.pathname;
  const id = path?.split("/")?.[path?.split("/")?.length - 1];
  const [getTestPaperData, setGetTestPaperData] = useState({});
  const [reloadModal] = useAtom(reloadModalAtom);

  const [isExamFinish, setIsExamFinish] = useState(false);
  // saving record for the first time, when user start the paper by an API call
  const [exampPaperResult, setExampPaperResult] = useAtom(examPaper);
  //all Questions that are selected or not selected , are saved in this state
  const [selectedOptions, setSelectedOptions] = useState([]);
  const router = useNavigate();
  const [loading, setLoading] = useState(false);
  const endPoint = path?.includes("sample")
    ? `/questions/group/sample/${id}`
    : `/questions/group/${id}`;
  const getTestDetails = () => {
    setLoading(true);
    getSingleTestPaperDetails(endPoint)
      .then((res) => {
        if (res) {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setGetTestPaperData(res?.data?.data);
        }
      })
      .catch(() => {
        router("/login");
        setLoading(false);
      });
  };
  useEffect(() => {
    if (id) getTestDetails();
  }, [id]);
  const updateExamPaperFun = async () => {
    const scores = selectedOptions
      ?.map((i) => {
        if (i?.isCorrect) {
          return 1;
        }
        return 0;
      })
      ?.reduce((prev, curr) => prev + curr, 0);

    updateExamPaper({
      id,
      body: {
        questions: selectedOptions,
        totalScore: scores,
      },
    })
      .then((res) => {
        setIsExamFinish(true);
        setExampPaperResult(res?.data?.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setIsExamFinish(false);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };
  return (
    <div>
      {loading ? (
        <div
          className=""
          style={{
            height: "100vh",
            overflow: "scroll",
            background: "#000",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Loader4 />
        </div>
      ) : (
        <div className="mx-5">
          {isExamFinish ? (
            <>
              <AnswerModule
                getTestPaperData={getTestPaperData}
                selectedOptions={selectedOptions}
                exampPaperResult={exampPaperResult}
                setSelectedOptions={setSelectedOptions}
              />
            </>
          ) : (
            <QuestionsModule
              getTestPaperData={getTestPaperData}
              setLoading={setLoading}
              setIsExamFinish={setIsExamFinish}
              isExamFinish={isExamFinish}
              updateExamPaperFun={updateExamPaperFun}
              setExampPaperResult={setExampPaperResult}
              exampPaperResult={exampPaperResult}
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions}
            />
          )}
        </div>
      )}
    </div>
  );
}
