import React from "react";
import "../style.css";

import Footer from "./Footer";
import { currentUser } from "../../../jotaiStore/user";
import { useAtom } from "jotai";
const Lesson = ({
  lessonName,
  getLessonsList,
  setSelectedIndex,
  selectedIndex,
  setSelectedTopic,
  setSelectPracticePaper,
}) => {
  const [user] = useAtom(currentUser);
  const allTopicsId = lessonName?.topics?.map((i) => i._id);

  const isAllCompleted = allTopicsId?.length
    ? allTopicsId.every((i) => user?.topics?.includes(i))
    : false;
  const userLessonTopic = allTopicsId?.filter((i) => user?.topics?.includes(i));
  let completePercentage = 0;
  const practicePaperCompleted = getLessonsList?.lessons
    ?.filter((j) => j?._id === lessonName?._id)
    ?.filter((i) => i?.testCompleted?.includes(user?._id))?.length;
  if (user?.practicePaperAccess && user?.lessonAccess) {
    if (!!allTopicsId?.length) {
      completePercentage = Math.round(
        (userLessonTopic?.length / allTopicsId?.length) * 100
      );
    }
  } else {
    console.log(
      "Error: Cannot calculate percentage. Empty array or undefined length."
    );
  }

  if (getLessonsList?.lessons?.length) {
    if (user?.practicePaperAccess && !user?.lessonAccess) {
      completePercentage = (practicePaperCompleted / 1) * 100;
    }
  }

  return (
    <div className="main lesson">
      <div className="lessonHeading">{lessonName?.lessonName}</div>
      <div className="section">
        <div className="text1">{lessonName?.lessonName}</div>
        <div>
          <div
            className="inprogressbtn mt-4 mt-md-0 "
            style={{
              textTransform: "uppercase",
            }}
          >
            {isAllCompleted ? "Completed" : "In Progress"}
          </div>
        </div>
      </div>
      <div className="topicSection ">
        <div
          className="d-block justify-content-between d-md-flex align-items-center   w-100 "
          style={{
            background: "#0b84e3",
            padding: 20,
            borderRadius: 5,
          }}
        >
          <div className="d-flex align-items-center">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="white"
                class="bi bi-clipboard2"
                viewBox="0 0 16 16"
              >
                <path d="M3.5 2a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5H12a.5.5 0 0 1 0-1h.5A1.5 1.5 0 0 1 14 2.5v12a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-12A1.5 1.5 0 0 1 3.5 1H4a.5.5 0 0 1 0 1z" />
                <path d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5" />
              </svg>
            </div>
            <div className="mx-2 h5 text-white">Lesson Content</div>
          </div>
          <div className="  mt-md-0 mt-2 d-flex align-items-center ">
            <div className="text-white">{completePercentage}% Complete</div>
            <div className="text-white mx-4 ">
              {user?.practicePaperAccess && !user?.lessonAccess
                ? `${practicePaperCompleted}/${
                    getLessonsList?.lessons?.find(
                      (j) => j?._id === lessonName?._id
                    )?.practicePaper?.length
                      ? 1
                      : 0
                  } `
                : user?.practicePaperAccess && user?.lessonAccess
                ? `${userLessonTopic?.length}/${allTopicsId?.length} `
                : ""}
              {user?.practicePaperAccess && !user?.lessonAccess
                ? "Papers"
                : user?.practicePaperAccess && user?.lessonAccess
                ? "Topics"
                : ""}
            </div>
          </div>
        </div>
        <div>
          {lessonName?.topics?.length ? (
            lessonName?.topics?.map((i) => {
              return (
                <div
                  className="d-flex align-items-center p-3"
                  role="button"
                  onClick={() => {
                    setSelectedTopic(i);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  {user?.topics?.includes(i?._id) ? (
                    <div
                      style={{
                        width: 45,
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="#0b84e3"
                        class="bi bi-check-circle-fill"
                        viewBox="0 0 17 17"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>
                    </div>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="#ccc"
                      class="bi bi-circle"
                      viewBox="0 0 20 20"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    </svg>
                  )}

                  <div className="mx-2 h6">{i?.name}</div>
                </div>
              );
            })
          ) : (
            <>
              <div className="d-flex align-items-center p-3">
                <div className="mx-2 h5 text-black ">No Topics</div>
              </div>
            </>
          )}
          {lessonName?.practicePaper?.length ? (
            <div
              className="d-flex align-items-center p-3"
              role="button"
              onClick={() => {
                if (user?.practicePaperAccess && !user?.lessonAccess) {
                  setSelectPracticePaper(lessonName?.practicePaper);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }
                if (user?.practicePaperAccess && user?.lessonAccess)
                  if (isAllCompleted) {
                    setSelectPracticePaper(lessonName?.practicePaper);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  } else {
                    alert(
                      "Please complete all the topics to unlock the practice paper."
                    );
                  }
              }}
            >
              <div
                style={{
                  width: 45,
                }}
              >
                {!lessonName?.testCompleted?.includes(user?._id) ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="27"
                    fill="#ccc"
                    class="bi bi-clipboard-check"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0"
                    />
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="27"
                    fill="#0b84e3"
                    class="bi bi-check-circle-fill"
                    viewBox="0 0 17 17"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                )}
              </div>
              <div className=" h5 ">Practice Question</div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="">
        <Footer
          isCompleted
          getLessonsList={getLessonsList}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
          setSelectedTopic={setSelectedTopic}
        />
      </div>
    </div>
  );
};

export default Lesson;
