import { DataService } from "../../utils/dataService/dataService";

const getFeatures = async () => {
  const response = await DataService.get(`/feature`);
  return response;
};
const deleteFeatures = async (id) => {
  const response = await DataService.get(`/feature/${id}`);
  return response;
};

export { getFeatures, deleteFeatures };
