import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useAtom } from "jotai";
import { currentUser } from "../../jotaiStore/user";
import { getOrderService, getUserTestPaper } from "../../services/user/service";
import { getFeatures } from "../../services/feature/service";
import SideBar from "./SideBar";
import Dashboard from "./Dashboard";
import MyProfile from "./MyProfile";
import EditModal from "./EditModal";
import EnrolledCourses from "./EnrolledCourses";
import TestResults from "./TestResults";
import OrderHistory from "./OrderHistory";
import { getEnrolledCourses } from "../../services/testPaper/service";
import { getLessonService } from "../../services/lesson/service";
import { Loader2 } from "../../components/loader";

const Profile = ({ type }) => {
  const [user] = useAtom(currentUser);
  const routePath = useLocation().pathname;

  const navigate = useNavigate();
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [features, setFeatures] = useState([]);
  const [lessonData, setLessonData] = useState([]);
  const [editModal, setEditModal] = useState({});
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Dashboard");
  const [selectedCourseTab, setSelectedCourseTab] = useState(1);
  const [getResult, setGetResult] = useState([]);
  const changeHandler = (e) => {
    setEditModal({ ...editModal, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (routePath) {
      switch (routePath) {
        case "/user/profile/dashboard":
          setSelectedTab("Dashboard");
          break;
        case "/user/profile/my-profile":
          setSelectedTab("My Profile");
          break;
        case "/user/profile/enrolled-courses":
          setSelectedTab("Enrolled Courses");
          break;
        case "/user/profile/test-results":
          setSelectedTab("Test Results");
          break;
        case "/user/profile/order-history":
          setSelectedTab("Order History");
          break;
        default:
          setSelectedTab("Dashboard");
      }
    }
  }, [routePath]);
  const dashboardData = [
    {
      id: 1,
      name: "Enrolled  Courses",
      icon: "ti-book",
      value: enrolledCourses?.data?.length || 0,
      color: "#e4e9fe",
      iconbgColor: "#ccd6fc",
      valueColor: "#3057ef",
      textColor: "#8ba0f7",
      onClick: () => {
        navigate("/user/profile/enrolled-courses");
        setSelectedCourseTab(1);
      },
    },
    {
      id: 2,
      name: "Lesson Courses",
      icon: "ti-bookmark-alt",
      value: lessonData?.data?.length || 0,
      color: "#f7ecfc",
      iconbgColor: "#f0dafa",
      valueColor: "#b966e7",
      textColor: "#d8a9f2",
      onClick: () => {
        navigate("/user/profile/enrolled-courses");
        setSelectedCourseTab(2);
      },
    },
    {
      id: 3,
      name: "Additional Resourses",
      icon: "ti-mobile",
      value: features?.data?.length || 0,
      color: "#f0e0ef",
      iconbgColor: "#e2c6e1",
      valueColor: "#800080",
      textColor: "#bb77b9",
      onClick: () => {
        navigate("/user/profile/enrolled-courses");
        setSelectedCourseTab(3);
      },
    },
  ];
  const enrolledCoursesTabs = [
    {
      id: 1,
      name: "Enrolled  Courses",
      icon: "ti-book",
    },
    {
      id: 2,
      name: "Lesson Courses",
      icon: "ti-bookmark-alt",
    },
    {
      id: 3,
      name: "Additional Resourses",
      icon: "ti-mobile",
    },
  ];
  const sideBarData = [
    {
      name: "Dashboard",
      icon: "ti-dashboard",
      route: "/user/profile/dashboard",
    },
    {
      name: "My Profile",
      icon: "ti-user",
      route: "/user/profile/my-profile",
    },
    {
      name: "Enrolled Courses",
      icon: "ti-book",
      route: "/user/profile/enrolled-courses",
    },
    {
      name: "Test Results",
      icon: "ti-pencil",
      route: "/user/profile/test-results",
    },
    {
      name: "Order History",
      icon: "ti-shopping-cart",
      route: "/user/profile/order-history",
    },
    {
      name: "Logout",
      icon: "ti-power-off",
    },
  ];

  const renderData = {
    Dashboard: (
      <Dashboard
        data={dashboardData}
        enrolledCourses={enrolledCourses}
        lessonData={lessonData}
      />
    ),
    "My Profile": <MyProfile setEditModal={setEditModal} />,
    "Enrolled Courses": (
      <EnrolledCourses
        data={enrolledCoursesTabs}
        setSelectedCourseTab={setSelectedCourseTab}
        selectedCourseTab={selectedCourseTab}
        enrolledCourses={enrolledCourses}
        lessonData={lessonData}
        features={features}
      />
    ),
    "Test Results": <TestResults data={getResult} />,
    "Order History": <OrderHistory data={orderData} />,
  };
  const getAllApiData = async () => {
    setLoading(true);
    const query = `type=mock`;
    let res1;
    let res2;
    let res3;
    let res4;
    let res5;
    try {
      [res1, res2, res3, res4, res5] = await Promise.all([
        getUserTestPaper(),
        getEnrolledCourses(query),
        getOrderService(query),
        getLessonService(),
        getFeatures(),
      ]);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
    setGetResult(res1.data);
    setEnrolledCourses(res2?.data);
    setOrderData(res3?.data);
    setLessonData(res4?.data);
    setFeatures(res5?.data);
    setLoading(false);
  };
  useEffect(() => {
    getAllApiData();
  }, []);

  useEffect(() => {
    if (!!localStorage.getItem("access_token") === false) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <Navbar />
      {loading ? (
        <div
          style={{
            height: "58vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader2 />
        </div>
      ) : (
        <div className="wpo-popular-area py-4 mt-4">
          <div className="container">
            <div className="wpo-popular-wrap">
              <div className="container-fluid ">
                <div className="row d-flex  justify-content-center">
                  {user?._id ? (
                    <div className="col-sm-auto bg-light sticky-md-top sticky-relative p-0 ">
                      <SideBar data={sideBarData} selectedTab={selectedTab} />
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="col-sm row  m-0 p-0 mx-sm-4 mt-0  mt-3 mt-lg-0">
                    {renderData[selectedTab]}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <EditModal
        editModal={editModal}
        setEditModal={setEditModal}
        changeHandler={changeHandler}
      />
      <Footer />
      <Scrollbar />
    </>
  );
};

export default Profile;
